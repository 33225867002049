import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  Stack,
  Alert,
  Paper,
  Typography,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as dayjs from "dayjs";

import { Layout } from "../../components/dashboard/layout";
import { useParams, useNavigate } from "react-router-dom";
import { StackLayout } from "src/components/dashboard/stackLayout";
import moment from "moment";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";

import Webcam from "react-webcam";
import { CheckCircleOutline } from "@mui/icons-material";

import axios from "axios";
import keys from '../../constants.js';
import loaderIcon from '../../asserts/loader-ajax.gif';

import { DataGrid } from '@mui/x-data-grid';

import UserCard from './UserCard';


const SelfAttestation = () => {

    const [users, setUsers] = useState([]);

    const navigate = useNavigate();

    function getRequestFromID() {
        const url = keys.APIPATH + '/getNotAttested.php';
        const requestOptions = {
          method: 'GET',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json' },
        };
    
        axios.post(url, {})
          .then(response => {
            setUsers(response.data.data);
        });
    }

    function handleCardClick(e)
    {
        navigate(`/att-form/`+e.id);
    }

    useEffect(() => {
        getRequestFromID();
        const interval = setInterval(() => {
            getRequestFromID();
          }, 10000);
          return () => clearInterval(interval);
    }, []);

    return (
        <Layout>
            <Box sx={{ p: 4 }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>Self Attestation</Typography>
                    <Grid container spacing={3} style={{marginTop: `10px`}}>
                        {users.map((user) => (
                            <UserCard key={user.id} user={user} onClick={handleCardClick}/>
                        ))}
                    </Grid>
                </div>
            </Box>
        </Layout>
    );
};

export default SelfAttestation;
