import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { ArrowLeftSharp, ChevronLeft } from "@mui/icons-material";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
   // paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const StackLayout = withAuthGuard((props) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(
    () => {
      handlePathnameChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  return (
    <>
      <div>
        <div
          style={{
            width: "24px",
            height: "24px",
            paddingTop: "24px",
            paddingLeft: "10px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeft /> 
        </div>
      </div>
      <LayoutRoot>
        <LayoutContainer><div style={{padding:"30px"}}>
        {children}
          </div></LayoutContainer>
      </LayoutRoot>
    </>
  );
});
