import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Stack,
  useMediaQuery,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  List,
  Collapse,
} from "@mui/material";
import { Scrollbar } from "src/components/common/scrollbar";
import { items } from "./config";
import { SideNavItem } from "./side-nav-item";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactLogo from "../../asserts/RaptorNet_color.svg";
import { ReactSVG } from "react-svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";

export const SideNav = (props) => {
  const { open, onClose } = props;

  const [navOpen, setNavOpen] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const content = (
    // <Scrollbar
    //   sx={{
    //     height: '100%',
    //     '& .simplebar-content': {
    //       height: '100%'
    //     },
    //     '& .simplebar-scrollbar:before': {
    //       background: 'neutral.400'
    //     }
    //   }}
    // >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <Box sx={{ p: 2 }}>
        <Box
          onClick={() => {
            navigate("/dashboard");
          }}
          sx={{
            display: "flex",
            justifyContent:"center",
            height: 40,
            backgroundColor: "transparent",
          }}
        >
   
          <ReactSVG
            style={{ width: "180px" }}
            src={ReactLogo}
            className="logo-svg"
          />
        </Box>
      </Box> */}

      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
          paddingTop: "90px",
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {items.map((item) => {
            return (
              <>
                <ListItemButton
                  onClick={() => {
                    if (item.children) {
                      setNavOpen({
                        ...navOpen,
                        [item.title]: !!!navOpen[item.title],
                      });
                    } else {
                      navigate(item.path);
                    }
                  }}
                  style={{
                    backgroundColor: navOpen[item.title] ? "#f0eded" : "inherit",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      component="span"
                      sx={{
                        alignItems: "center",
                        color: "neutral.400",
                        display: "inline-flex",
                        justifyContent: "center",
                        mr: 2,
                        // ...(active && {
                        //   color: '#1976d2'
                        // })
                      }}
                      className="sidebar-icon"
                    >
                      {item.icon}
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                  {item.children &&
                    item.children.length &&
                    (navOpen[item.title] ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                {item.children && (
                  <Collapse
                    in={navOpen[item.title]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding   style={{
                    backgroundColor: navOpen[item.title] ? "#f0eded" : "inherit",
                  }}>
                      {item.children.map((child) => {
                        return (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => {
                              navigate(child.path);
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                alignItems: "center",
                                color: "neutral.400",
                                display: "inline-flex",
                                justifyContent: "center",
                                mr: 2,
                                // ...(active && {
                                //   color: '#1976d2'
                                // })
                              }}
                              className="sidebar-icon"
                            >
                              {child.icon}
                            </Box>
                            <ListItemText primary={child.title} />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </>
            );
          })}
        </List>
        {/* <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {items.map((item) => {
              const active = item.path ? (pathname === item.path) : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack> */}
      </Box>
    </Box>
    // </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "#F9F9F9",
            color: "common.black",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "#F9F9F9",
          color: "common.black",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
