import React, { useState, useEffect } from "react";
import { Layout } from "../../components/dashboard/layout";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Card,
  Tabs,
  Tab,
  CardContent,
  Link
} from "@mui/material";

import ArrowDownIcon from "@heroicons/react/24/solid/ArrowDownIcon";
import ArrowUpIcon from "@heroicons/react/24/solid/ArrowUpIcon";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import VisitorHistory from "./visitorHistory";
import VisitorPreRegistered from "./visitorPreRegistered";
import TabPanel from "src/components/common/TabPanel";
import { StackLayout } from "src/components/dashboard/stackLayout";
import "./visitors.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import keys from '../../constants.js';

const Visitors = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedTab, SetSelectedTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs())

  const handleChange = (event, newValue) => {
    SetSelectedTab(newValue);
  };

  const [stats,setStats] = React.useState([]);
  
  function getHistory()
  {
    const url = keys.APIPATH+'/visitorHistory.php?type=new&dt='+selectedDate;
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {};

		axios.post(url, reqBody)
			.then(response => {
				setStats(response.data.data);
        setItems(response.data.list);
			}
		);
  }

  function handleAddVisitor()
  {
    navigate('/visitor/-1');
  }

  function handleDateChange(val)
  {
    setSelectedDate(val);
    const url = keys.APIPATH+'/visitorHistory.php?dt='+val;
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {};

		axios.post(url, reqBody)
			.then(response => {
				setStats(response.data.data);
        setItems(response.data.list);
			}
		);
  }

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <Layout>
      <Box className="visitors">
        <Card elevation={10} sx={{ m: "10px" }}>
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="center"
                spacing={3}
                sx={{ width: "150px" }}
              >
                <Stack
                  spacing={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ArrowUpIcon
                      color="#9747FF"
                      className="visitor-metrics-icon"
                    />
                    <Typography variant="h4">{stats.total}</Typography>
                  </Stack>
                  <div>Total Visitors</div>
                </Stack>
              </Stack>
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
                sx={{ width: "150px" }}
              >
                <Stack
                  spacing={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ArrowUpIcon
                      color="#50F915"
                      className="visitor-metrics-icon"
                    />
                    <Typography variant="h4">{stats.in}</Typography>
                  </Stack>
                  <div>Checked-in</div>
                </Stack>
              </Stack>
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
                sx={{ width: "150px" }}
              >
                <Stack
                  spacing={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ArrowUpIcon
                      color="#F17F2C"
                      className="visitor-metrics-icon"
                    />
                    <Typography variant="h4">{stats.out}</Typography>
                  </Stack>
                  <div>Checked-out</div>
                </Stack>
              </Stack>
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
                sx={{ width: "150px" }}
              >
                {/* {dayjs().format("LL")}

                <CalendarIcon style={{ width: "24px" }} /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="visitingDate"
                    format="MMM DD,YYYY"
                    value={selectedDate}
                    onChange={(newValue) => {
                     handleDateChange(newValue)
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
        className="visitors"
      >
        <Container maxWidth="xl" style={{ padding: "10px" }}>
          <Button color="primary" className="addBtn" onClick={() => handleAddVisitor()} variant="contained">Add</Button>
          <Stack spacing={3}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="History" />
              <Tab label="Pre Registration" />
            </Tabs>
            <TabPanel className="tabpanel" value={selectedTab} index={0}>
              <VisitorHistory internalItems={items} selectDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <VisitorPreRegistered  />
            </TabPanel>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default Visitors;
