import React, { useState, useEffect } from "react";
import { Layout } from "../../components/dashboard/layout";
import { Grid, TextField, Button, Alert, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'; // Ensure this imports the initialized Firestore instance
import { useNavigate } from "react-router-dom";

const StaffCreate = () => {
  const [user, setUser] = useState({
    userId: '',
    name: '',
    email: '',
    role: '',
    rfid: ''
  });
  const [existingUsers, setExistingUsers] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExistingUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map(doc => doc.data());
        setExistingUsers(usersData);
      } catch (error) {
        console.error("Error fetching existing users: ", error);
      }
    };

    fetchExistingUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { userId, email, rfid } = user;

    const idExists = existingUsers.some(u => u.userId === userId);
    const emailExists = existingUsers.some(u => u.email === email);
    const rfidExists = existingUsers.some(u => u.rfid === rfid);

    if (idExists || rfidExists) {
      setError('ID, Email, or RFID already exists');
      return;
    }

    try {
      // Create new user
      const userRef = doc(db, "users", user.userId);
      await setDoc(userRef, user);
      // Create entry in "id_data" collection
      const idDataRef = doc(db, "id_data", user.rfid);
      await setDoc(idDataRef, { [user.rfid]: user.userId });

      setSuccess(true);
      setError('');
      // Optionally, navigate to another page after a delay
      setTimeout(() => {
        setSuccess('User created successfully!');
        navigate('/staffs'); // Adjust the path as needed
      }, 500);
    } catch (error) {
      console.error("Error saving user data: ", error);
      setError('Error saving user data');
    }
  };

  return (
    <Layout>
      <div style={{ padding: "20px" }}>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">User created successfully!</Alert>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ID"
                name="userId"
                value={user.userId}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={user.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  name="role"
                  value={user.role}
                  onChange={handleChange}
                  label="Role"
                >
                  <MenuItem value="student">Student</MenuItem>
                  <MenuItem value="staff">Staff</MenuItem>
                  <MenuItem value="parent">Parent</MenuItem>
                  <MenuItem value="efm">EFM</MenuItem>
                  <MenuItem value="vendor">Vendor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="RFID"
                name="rfid"
                value={user.rfid}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Layout>
  );
};

export default StaffCreate;
