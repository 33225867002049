import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  OutlinedInput,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  InputAdornment,
  CardContent,
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Scrollbar } from "src/components/common/scrollbar";
import { Layout } from "../../components/dashboard/layout";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { StackLayout } from "src/components/dashboard/stackLayout";
import './visitorInfo.scss';
import axios from "axios";
import keys from '../../constants.js';
import loaderIcon from '../../asserts/loader-ajax.gif';

const VisitorInfo = ({userData}) => {

  const [items, setItems] = useState([]);
  const [visitorInfo, setVisitorInfo] = useState({});
  const [showModal, setShowModal] = useState(false);

  const count = items.length;

  const [showLoader, setShowLoader] = useState("none");

  const rowsPerPage = 10;
  const page = 0;

  const onPageChange = () => {};
  const navigate = useNavigate();
  const onRowsPerPageChange = () => {};

  function processAnchor(info)
  {
    var dt = info.split(',');
    var results = [];
    dt.forEach((docu, index) => {
      results.push(
        <><a target="new" key={index} href={keys.APIPATH+'/'+docu} download>{docu.replace('uploads/','')}</a><br/></>
      );
    });
    return results;
  }
  function handleCloseModal()
  {
    setShowModal(false);
  }
  const [checkedOut, setCheckedOut] = useState(false);

  function getVisitorDetails()
  {
    const url = keys.APIPATH+'/getVisitorDetails.php';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {id: userData};

		axios.post(url, reqBody)
			.then(response => {

        if(response.data[0].check_out_time != '')
        {
          setCheckedOut(true);
        }
        else
        {
          setCheckedOut(false);
        }

				setItems([
          {
            id: response.data[0].id,
            checkedInTime: response.data[0].check_in_time,
            checkedOutTime: response.data[0].check_out_time,
            visitingHost: response.data[0].visiting_host,
            dateOfVisit: response.data[0].dateofVisit,
            photo: processAnchor(response.data[0].photo),
            documents: processAnchor(response.data[0].documents)
          },
        ]);

        setVisitorInfo({
          visitorName: response.data[0].name,
          mobile: response.data[0].phone,
          createdDate: response.data[0].dateofVisit,
          createdBy: "--",
          visitorType: response.data[0].visitor_type,
          photo: response.data[0].photo,
          id: response.data[0].id,
          attestation: response.data[0].attestation
        });
			}
		);
  }

  function handleCheckOut()
  {
    setShowLoader("");
    const url = keys.APIPATH+'/checkOutVisitor.php';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {id: visitorInfo.id};

		axios.post(url, reqBody)
			.then(response => {
        setShowModal(true);
				getVisitorDetails();
        setShowLoader("none");
			}
		);
  }

  function handleAttestation(e)
  {
    navigate('/view-att/'+e);
  }

  //console.log(visitorInfo);

  useEffect(() => {
    getVisitorDetails();
  }, []);

  return (
    // <Layout>
    <>
      <Box  sx={{ m: "20px" }}>
        <CardContent>
          <Stack direction={"row"} justifyContent="space-between">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={visitorInfo.visitorName}
                src={keys.APIPATH+"/"+visitorInfo.photo}
                sx={{ width: 100, height: 100 }}
              ></Avatar>
            </div>
            <div style={{ flexGrow: "1", marginLeft: "30px" }}>
              <div style={{ marginBottom: "30px" }}>
                <Typography variant="h5">{visitorInfo.visitorName}</Typography>
                <img src={loaderIcon} style={{top: `17%`,width: `5%`,right: `15%`,position: `absolute`,display: `${showLoader}`}}/>
                {!checkedOut ? (
                  <Button onClick={() => handleCheckOut()} style={{float: `right`,top: `-30px`,right: `15px`}} color="primary" variant="contained">Check Out</Button>
                  ) : (
                  ""
                )}
                {showModal && (
                <Dialog
                  maxWidth="xl"
                  open
                  onClose={() => {
                    handleCloseModal()
                  }}
                >
                  <DialogTitle style={{ margin: "0 16px" }}>
                    <Typography variant="h5"> Mail has been sent successfully</Typography>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        handleCloseModal()
                      }}
                      sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                </Dialog>)}
              </div>
              <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
              <div className="label-item">
                <label>Contact Number</label>
                <div>
                  {visitorInfo.mobile}
                </div>
              </div>
              <div  className="label-item">
                <label>Visitor Type</label>
                <div>
                  {visitorInfo.visitorType}
                </div>
              </div>
              <div  className="label-item">
                <label>Created Date</label>
                <div>
                  {visitorInfo.createdDate}
                </div>
              </div>
              <div  className="label-item">
                <label>Created By</label>
                <div>
                  {visitorInfo.createdBy}
                </div>
              </div>
              <div  className="label-item">
                <label>Self-Attestation</label>
                <div>
                  {
                  visitorInfo.attestation && visitorInfo.attestation == 'Yes' ? 
                  (
                    <><a href="#" targe="new" onClick={() => window.open('/view-att/'+visitorInfo.id,'_blank', 'rel=noopener noreferrer')}>View</a></>
                  )
                  : (
                    <><a href="#" targe="new" onClick={() => alert("User didn't agreed yet")}>View</a></>
                  )
                  }
                  
                </div>
              </div>
              </Stack>
            </div>
          </Stack>
          <Card sx={{ m: "20px" }}>
            <Scrollbar>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Visting Host</TableCell>
                      <TableCell>Date of Visit</TableCell>
                      <TableCell>Checked-In Time</TableCell>
                      <TableCell>Checked-Out Time</TableCell>
                      <TableCell>Photo(s)</TableCell>
                      <TableCell>Document(s)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((visitors,index) => {
                      return (
                        <TableRow hover key={index}>
                          <TableCell>{visitors.visitingHost}</TableCell>
                          <TableCell>{visitors.dateOfVisit}</TableCell>
                          <TableCell>{visitors.checkedInTime}</TableCell>
                          <TableCell>{visitors.checkedOutTime}</TableCell>
                          <TableCell>{visitors.photo}</TableCell>
                          <TableCell>{visitors.documents}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
            <TablePagination
              component="div"
              count={count}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Card>
        </CardContent>
      </Box>
      
     
        </>
    // </Layout>
  );
};

export default VisitorInfo;
