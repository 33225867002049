import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Ensure this imports the initialized Firestore instance
import { Layout } from "./components/dashboard/layout";
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const ErrorLogs = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchErrorLogs();
  }, []);

  const fetchErrorLogs = async () => {
    try {
      const logsSnapshot = await getDocs(collection(db, 'error_logs'));
      const logsList = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Filter to only include unique EPCs
      const uniqueLogs = logsList.filter(
        (log, index, self) => index === self.findIndex((t) => t.epc === log.epc)
      );
  
      setLogs(uniqueLogs);
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };
  

  const columns = [
    { field: 'datetime', headerName: 'Datetime', flex: 1 },
    { field: 'epc', headerName: 'EPC', flex: 1 },
  ];

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>Error Logs</Typography>
        <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
          <DataGrid
            rows={logs}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
          />
        </div>
      </Box>
    </Layout>
  );
};

export default ErrorLogs;
