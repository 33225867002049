import React, { useEffect, useState } from "react";
import { Layout } from "../../components/dashboard/layout";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  OutlinedInput,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  InputAdornment,
  CardContent,
  Chip,
} from "@mui/material";
import ArrowDownIcon from "@heroicons/react/24/solid/ArrowDownIcon";
import ArrowUpIcon from "@heroicons/react/24/solid/ArrowUpIcon";
import { Scrollbar } from "src/components/common/scrollbar";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import keys from '../../constants.js';

const VisitorPreRegistered = () => {
  const [items, setItems] = useState([]);

  const count = items.length;

  const rowsPerPage = 10;
  const page = 0;

  const onPageChange = () => {};
  const navigate = useNavigate();
  function getHistory()
  {
    const url = keys.APIPATH+'/visitorHistory.php?type=preRegister';
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {};

		axios.post(url, reqBody)
			.then(response => {
				setItems(response.data.list);
			}
		);
  }

  const getStatusColor = (status) => {
    if (status == "1") {
      return "default";
    } else if (status == "2") {
      return "primary";
  } else if (status == "3") {
    return "warning";
  } 
  else { return "success"; }
    
  };

  const onRowsPerPageChange = () => {};
  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          paddingBottom: "8px",
        }}
      >
      <OutlinedInput
        defaultValue=""
        fullWidth
        placeholder="Search"
        onChange={(e) => {}}
        startAdornment={
          <InputAdornment position="start">
            <SvgIcon color="action" fontSize="small">
              <MagnifyingGlassIcon />
            </SvgIcon>
          </InputAdornment>
        }
        sx={{ maxWidth: 500 }}
      />
    </div>
      <Card>
        <Scrollbar>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Visitor Name</TableCell>
                  <TableCell>Visitor ID No</TableCell>
                  <TableCell>Checked-In Time</TableCell>
                  <TableCell>Checked-Out Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Visting Host</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((visitors) => {
                  return (
                    <TableRow
                      hover
                      key={visitors.id}
                      onClick={() => {
                        navigate(`/preregister/${visitors.id}`);
                      }}
                    >
                      <TableCell>
                        <Stack alignItems="center" direction="row" spacing={2}>
                          <Avatar src={visitors.avatar}></Avatar>
                          <Typography variant="subtitle2">
                            {visitors.visitorName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{visitors.visitorIdNo}</TableCell>
                      <TableCell>{visitors.checkedInTime}</TableCell>
                      <TableCell>{visitors.checkedOutTime}</TableCell>
                      <TableCell>
                        <Chip
                          label={(visitors.status==1)?'Registered' : (visitors.status==2) ? 'Check-In' : (visitors.status==3) ? 'Registered-Attested' : 'Check-Out'}
                          /*label={(visitors.status==1)?'Register': (visitors.checkedOutTime=='' || visitors.checkedOutTime==null )?'Check-In':'Check-Out'}*/
                          color={getStatusColor(visitors.status)}
                        ></Chip>
                      </TableCell>
                      <TableCell>{visitors.visitingHost}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </div>
  );
};

export default VisitorPreRegistered;
