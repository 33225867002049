import * as React from "react";
import { Layout } from "../../components/dashboard/layout";
import "./dashboard.scss";
import { useNavigate } from "react-router-dom";
import { items } from "../../components/dashboard/config";
import { useMediaQuery } from "@mui/material";

const Dashboard = () => {
  const userData = localStorage.getItem('userData');
  let uData = JSON.parse(userData);
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  return (
    <Layout>
      <div className="dashboard">
       {!lgUp &&  <div className="nav-list">
          {items.map((list, index) => {
            return (
              <div
                className="nav-item"
                onClick={() => {
                  navigate(list.path);
                }}
              >
                <div className="icon-container">{list.icon}</div>
                <div className="nav-title">{list.title}</div>
              </div>
            );
          })}
        </div>}
        <p></p>
      </div>
    </Layout>
  );
};

export default Dashboard;
