import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebase'; // Ensure this imports the initialized Firestore instance
import { Layout } from "./components/dashboard/layout";
import { Box, Typography, Grid, TextField, Button } from '@mui/material';

const ImageGallery = () => {
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const imageFolderUrl = 'https://raptornet.aischennai.org/screening-manager/api/images/'; // Replace with your server path
  const [missingImages, setMissingImages] = useState([]);

  const handleSearch = async () => {
    setLoading(true);
    try {
      // Perform the query assuming userId is a string
      const qString = query(collection(db, 'users'), where('userId', '==', userId));
      const querySnapshotString = await getDocs(qString);
      
      // If no results are found, perform the query assuming userId is a number
      if (querySnapshotString.empty) {
        const qNumber = query(collection(db, 'users'), where('userId', '==', Number(userId)));
        const querySnapshotNumber = await getDocs(qNumber);
  
        if (!querySnapshotNumber.empty) {
          const userData = querySnapshotNumber.docs[0].data();
          setUser(userData);
        } else {
          setUser(null);
        }
      } else {
        const userData = querySnapshotString.docs[0].data();
        setUser(userData);
      }
    } catch (error) {
      console.error("Error fetching user: ", error);
    }
    setLoading(false);
  };
  

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }

    try {
      const response = await fetch('https://raptornet.aischennai.org/screening-manager/api/uploadBulkImages.php', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      setUploadMessage(result.join('\n'));
    } catch (error) {
      setUploadMessage('Error uploading files');
    }
  };

  const handleCheckImages = async () => {
    setLoading(true);
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = usersSnapshot.docs.map(doc => doc.data());
      
      const missingImagesList = [];
      
      for (const user of usersData) {
        const imageUrl = `${imageFolderUrl}${user.userId}.jpg`;
        try {
          const response = await fetch(imageUrl);
          if (!response.ok) {
            missingImagesList.push(user.userId);
          }
        } catch (error) {
          missingImagesList.push(user.userId);
        }
      }
      
      setMissingImages(missingImagesList);
    } catch (error) {
      console.error("Error checking images: ", error);
    }
    setLoading(false);
  };
  

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>Image Gallery</Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <TextField
            label="Search by User ID"
            variant="outlined"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading}>
            Search
          </Button>
          <Button variant="contained" color="secondary" onClick={handleCheckImages} disabled={loading}>
  Check Missing Images
</Button>

        </Box>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Grid container spacing={2}>
            {user ? (
              <Grid item xs={6} sm={4} md={2}>
                <Box sx={{ p: 2, textAlign: 'center', position: 'relative' }}>
                  <img
                    src={`${imageFolderUrl}${user.userId}.jpg`}
                    alt={`User ${user.userId}`}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <Typography
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      bgcolor: 'rgba(0, 0, 0, 0.6)',
                      color: 'white',
                      textAlign: 'center',
                      py: 1
                    }}
                  >
                    {user.name}
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Typography>No image found</Typography>
            )}
          </Grid>
        )}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>Upload Images</Typography>
          <TextField
            type="file"
            inputProps={{ multiple: true }}
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            sx={{ mt: 2 }}
          >
            Upload
          </Button>
          {uploadMessage && (
            <Typography variant="body1" sx={{ mt: 2, whiteSpace: 'pre-line' }}>
              {uploadMessage}
            </Typography>
          )}
        </Box>
        {missingImages.length > 0 && (
  <Box sx={{ mt: 4 }}>
    <Typography variant="h5">Missing Images</Typography>
    <ul>
      {missingImages.map((id, index) => (
        <li key={index}>{id}</li>
      ))}
    </ul>
  </Box>
)}
      </Box>

    </Layout>
  );
};

export default ImageGallery;
