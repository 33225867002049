import React, { useEffect, useState } from 'react';
import { collection, getDocs, setDoc, doc, addDoc, getDoc, query, where } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, Typography, Avatar, Alert, TextField, Autocomplete, Button } from '@mui/material';
import useScanDetection from 'use-scan-detection';

const ManualCheckOut = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [alert, setAlert] = useState(null);
  const [indianTime, setIndianTime] = useState("");
  const [loading, setLoading] = useState("");
  const [actionType, setActionType] = useState(null); // To keep track of CheckIn/CheckOut action

  useScanDetection({
    onComplete: (input) => handleUsbInput(input),
    minLength: 3,
    maxLength: 20,
    averageSpeed: 30,
  });

  const updateTime = () => {
    const now = new Date();
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };

    const formatter = new Intl.DateTimeFormat('en-IN', options);
    const parts = formatter.formatToParts(now);

    const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value} ${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;
    setIndianTime(formattedDate);
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersData = querySnapshot.docs.map(doc => doc.data());
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const addLogEntry = async (userId, type) => {
    try {
      const logRef = collection(db, 'logs');
      await addDoc(logRef, {
        datetime: indianTime,
        id: userId,
        type: type
      });
      console.log('Log entry added successfully');
    } catch (error) {
      console.error('Error adding log entry:', error);
    }
  };

  const handleUsbInput = async (input) => {
    setLoading("Loading...");
    setAlert(null);
  
    const normalizedInput = input.replaceAll('Shift', '').toUpperCase();
  
    try {
      const docRefUpper = doc(db, 'id_data', normalizedInput);
      const docRefLower = doc(db, 'id_data', normalizedInput.toLowerCase());
  
      const [docSnapUpper, docSnapLower] = await Promise.all([
        getDoc(docRefUpper),
        getDoc(docRefLower)
      ]);
  
      let userId = null;
      if (docSnapUpper.exists()) {
        userId = docSnapUpper.data()[normalizedInput];
      } else if (docSnapLower.exists()) {
        userId = docSnapLower.data()[normalizedInput.toLowerCase()];
      }
  
      if (userId) {
        const matchedUser = users.find(user => user.userId === userId);
        if (matchedUser) {
          setSelectedUser(matchedUser);
          setActionType('out'); // Set action type to 'out' for check-out
          handleCheckOut(matchedUser); // Automatically check-out the user
        } else {
          setAlert({ severity: 'error', message: 'No matching user found for the given RFID.' });
        }
      } else {
        setAlert({ severity: 'error', message: 'No matching RFID found in the database.' });
      }
    } catch (error) {
      console.error("Error checking RFID:", error);
      setAlert({ severity: 'error', message: 'Error processing RFID input.' });
    } finally {
      setLoading("");
    }
  };
  
  
  const handleCheckIn = async (user) => {
    setLoading("Processing Check-In...");
    setAlert(null);
    try {
      // Add log entry for check-in
      await addLogEntry(user.userId, 'in');
      setAlert({ severity: 'success', message: 'Check-In successful' });
    } catch (error) {
      setAlert({ severity: 'error', message: 'Error processing Check-In' });
    } finally {
      setLoading("");
    }
  };  

  const handleCheckOut = async () => {
    setLoading("Processing Check-Out...");
    setAlert(null);
    setActionType('out');
    try {
      // Add log entry for check-out
      await addLogEntry(selectedUser.userId, 'out');
      setAlert({ severity: 'success', message: 'Check-Out successful' });
    } catch (error) {
      setAlert({ severity: 'error', message: 'Error processing Check-Out' });
    } finally {
      setLoading("");
    }
  };

  useEffect(() => {
    updateTime();
    fetchUsers();
  }, []);

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>Manual User Check-Out</Typography>
        {alert && (
          <Alert severity={alert.severity} sx={{ mb: 2 }}>
            {alert.message}
          </Alert>
        )}
        <Autocomplete
          options={users}
          getOptionLabel={(option) => `${option.userId} - ${option.name}`}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
            setActionType(null); // Reset action type when a new user is selected
          }}
          renderOption={(props, option) => (
            <li {...props} data-rfid={option.rfid}>
              {`${option.userId} - ${option.name}`}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search User"
              variant="outlined"
              fullWidth
            />
          )}
        />
        {selectedUser && (
          <Box sx={{ mt: 3 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    p: 3,
                    borderRadius: '10px',
                    backgroundColor: actionType === 'in' ? '#d4edda' : actionType === 'out' ? '#f8d7da' : 'transparent',
                    mt: 3,
                    border: actionType === 'in' ? '2px solid #155724' : actionType === 'out' ? '2px solid #721c24' : 'none',
                }}
                >
              <Avatar 
                src={`https://raptornet.aischennai.org/screening-manager/api/images/${selectedUser.userId}.jpg`} 
                alt={selectedUser.name}
                sx={{ width: 150, height: 150 }}
              />
              <Box>
                <Typography variant="h4">{selectedUser.name}</Typography>
                <Typography variant="h4">{selectedUser.userId}</Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={() => handleCheckIn(selectedUser)} disabled={loading !== ""}>
                Check-In
              </Button>
              <Button variant="contained" color="secondary" onClick={() => handleCheckOut(selectedUser)} disabled={loading !== ""}>
                Check-Out
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default ManualCheckOut;
