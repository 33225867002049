import * as React from 'react';
import {Layout} from '../../components/dashboard/layout';


const Profile = () => {

    return (

        <Layout>
            Profile page
        </Layout>
    )

}

export default Profile