import PropTypes from "prop-types";
import BellIcon from "@heroicons/react/24/solid/BellIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";
import ReactLogo from "../../asserts/RaptorNet_color.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import keys from '../../constants.js';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 84;

export const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const accountPopover = usePopover();
  const navigate = useNavigate();

  const [deviceID, setDeviceID] = useState("");

  const userData = localStorage.getItem('userData');
  let uData = JSON.parse(userData);


  useEffect(() => {
    
  }, []);

  return (
    <>
      <Box
        component="header"
        className="mainNavHeader"
        id="noPrint"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: {
            // lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            // lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
          backgroundColor:  "rgba(255, 255, 255, 0.8)",
          border: "1px solid rgba(0, 0, 0, 0.5)",//: "inherit",
          zIndex: 500000,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {lgUp && (
              <div
                style={{ textAlign: "center", width: "150px" }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                <ReactSVG src={ReactLogo} className="logo-svg" />
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ position: "relative", left: "33px", top: "-9px" }}
                >
                  {props.subText}
                </Typography>
              </div>
            )}
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            style={{
              position: "absolute",
              left: "calc(50% - 83px)",
              top: props.subText ? "15px" : "23px",
            }}
          >
            {!lgUp && (
              <div
                style={{ textAlign: "center", width: "150px" }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                <ReactSVG src={ReactLogo} className="logo-svg" />
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ position: "relative", left: "33px", top: "-9px" }}
                >
                  {props.subText}
                </Typography>
              </div>
            )}
          </Stack>
          
          <Stack alignItems="center" direction="row" spacing={2}>
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
              src={uData.picture}
            />
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
};
