import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress';


import { Layout } from "../../components/dashboard/layout";
import axios from "axios";
import keys from '../../constants.js';

import { DataGrid } from '@mui/x-data-grid';
import { TransitionGroup } from 'react-transition-group';

const DecalRequests = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [deliveredRequests, setDeliveredRequests] = useState([]);
  const [showDelivered, setShowDelivered] = useState(false); // Toggle for showing delivered items
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [decalInput, setDecalInput] = useState("");
  const [pickedUpBy, setPickedUpBy] = useState("Self"); // Default to "Self"
  const [decalNoFilter, setDecalNoFilter] = useState("");

  // Filter states
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [showPlainTable, setShowPlainTable] = useState(false);

  const [isLoadingDelivered, setIsLoadingDelivered] = useState(false);

const togglePlainTableView = () => {
  setShowPlainTable(!showPlainTable);
};

  const columns = [
    { field: 'userName', headerName: "User Name", width: 300 },
    { field: 'vehicleInfo', headerName: 'Vehicle Info', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        let backgroundColor = '';
        let color = '#fff'; // White text color for both

        if (params.value === 'Pending') {
          backgroundColor = '#EE8523'; // Orange for Pending
        } else if (params.value === 'Alloted') {
          backgroundColor = '#68ad45'; // Green for Alloted
        }

        return (
          <div style={{ backgroundColor, color, padding: '5px', borderRadius: '4px', textAlign: 'center' }}>
            {params.value}
          </div>
        );
      },
    },
    { field: 'reqDate', headerName: 'Request Date', flex: 1 },
    { field: 'issueDate', headerName: 'Issued Date', flex: 1 },
    { field: 'decalNo', headerName: 'Decal No.', flex: 1 },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return null;
        }

        const gradeText = params.value.replace('Grade ', '');
        const gradeNumber = parseInt(gradeText, 10);

        if (isNaN(gradeNumber)) {
          return null;
        }

        let displayText = '';
        let backgroundColor = '';

        if (gradeNumber <= 5) {
          displayText = 'ES'; // Elementary School
          backgroundColor = '#68ad45'; // Green
        } else if (gradeNumber <= 8) {
          displayText = 'MS'; // Middle School
          backgroundColor = '#EE8523'; // Orange
        } else {
          displayText = 'HS'; // High School
          backgroundColor = '#d71920'; // Red
        }

        return (
          <div style={{ backgroundColor, color: '#fff', padding: '5px', borderRadius: '4px', textAlign: 'center' }}>
            {displayText}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getRequests();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [nameFilter, idFilter, vehicleFilter, statusFilter, requests]);

  const getRequests = () => {
    const url = keys.APIPATH1 + '/getDecalRequest.php';
    axios.post(url, {})
      .then(response => {
        if (response.data && response.data.html) {
          const formattedRequests = response.data.html.map(req => ({
            id: req[6],
            userName: req[0],
            vehicleInfo: req[1],
            status: req[2],
            reqDate: req[3],
            issueDate: req[4],
            decalNo: req[5],
            driverName: req[7],  // Assuming driverName is at index 7
            driverNumber: req[8], // Assuming driverNumber is at index 8
            grade: req[10],        // Grade value from the 10th key (index 9)
            students: req[11] ? JSON.parse(req[11]) : [], // Parsing students data from the 11th index
            make: req[12],        // Make value from the 12th index
            model: req[13],       // Model value from the 13th index
            regNo: req[14],
            color: req[9],     // Registration Number value from the 14th index
            uniqId: req[15]
          }));
          setRequests(formattedRequests);
          setFilteredRequests(formattedRequests.filter(req => req.status !== 'Delivered'));
          setDeliveredRequests(formattedRequests.filter(req => req.status === 'Delivered'));
        }
      })
      .catch(error => {
        console.error('Error fetching requests:', error);
      });
  };

  const applyFilters = () => {
    let filtered = requests;
  
    // Apply Name filter
    if (nameFilter) {
      filtered = filtered.filter(request =>
        request.userName.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }
  
    // Apply ID filter
    if (idFilter) {
      filtered = filtered.filter(request => request.id.toString().includes(idFilter));
    }
  
    // Apply Vehicle filter
    if (vehicleFilter) {
      filtered = filtered.filter(request =>
        request.vehicleInfo.toLowerCase().includes(vehicleFilter.toLowerCase())
      );
    }
  
    // Apply Status filter, but show all except 'Delivered'
    if (statusFilter) {
      filtered = filtered.filter(request =>
        request.status.toLowerCase().includes(statusFilter.toLowerCase())
      );
    } else {
      // Show all statuses except 'Delivered'
      filtered = filtered.filter(request => request.status !== 'Delivered');
    }
  
    // Apply Decal Number filter
    if (decalNoFilter) {
      filtered = filtered.filter(request =>
        request.decalNo.toLowerCase().includes(decalNoFilter.toLowerCase())
      );
    }
  
    // Set the filtered requests, excluding 'Delivered'
    setFilteredRequests(filtered);
  };
  

  const handleShowDelivered = async () => {
    setIsLoadingDelivered(true);
    try {
        toggleShowDelivered();
    } finally {
        setIsLoadingDelivered(false);
    }
};

  const handleClickOpen = (id, row) => {
    setSelectedRow(row);
    setDecalInput(row.decalNo || "");
    setPickedUpBy(row.pickedUpBy || "Self"); // Set the pickedUpBy state
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setDecalInput("");
    setPickedUpBy("Self");
  };

  const handleDecalInputChange = (event) => {
    setDecalInput(event.target.value);
  };

  const handlePickedUpByChange = (event) => {
    setPickedUpBy(event.target.value);
  };

  const handleSubmit = (data) => {
    console.log(data);
    const url = keys.APIPATH1 + '/approveDecal.php';
    axios.post(url, { id: selectedRow.id, decalInput: decalInput, otherData: data, pickedUpBy: pickedUpBy })
      .then(response => {
        alert("Done");
        getRequests(); // Refresh the list after submission
      })
      .catch(error => {
        console.error('Error approving decal:', error);
      });
    handleClose();
  };

  const handleDeliveredClick = () => {
    const url = keys.APIPATH1 + '/deliverDecal.php';
    axios.post(url, { id: selectedRow.id, pickedUpBy: pickedUpBy })
      .then(response => {
        alert("Marked as Delivered");
        setRequests(requests.filter(req => req.id !== selectedRow.id));
        setFilteredRequests(filteredRequests.filter(req => req.id !== selectedRow.id));
        setDeliveredRequests([...deliveredRequests, { ...selectedRow, status: 'Delivered' }]);
      })
      .catch(error => {
        console.error('Error marking as delivered:', error);
      });
    handleClose();
  };

  const toggleShowDelivered = () => {
    const url = keys.APIPATH1 + '/getDeliveredDecalRequest.php';
    axios.post(url, {})
      .then(response => {
        if (response.data && response.data.html) {
          const formattedRequests = response.data.html.map(req => ({
            id: req[6],
            userName: req[0],
            vehicleInfo: req[1],
            status: req[2],
            reqDate: req[3],
            issueDate: req[4],
            decalNo: req[5],
            driverName: req[7],  // Assuming driverName is at index 7
            driverNumber: req[8], // Assuming driverNumber is at index 8
            grade: req[10],        // Grade value from the 10th key (index 9)
            students: req[11] ? JSON.parse(req[11]) : [], // Parsing students data from the 11th index
            make: req[12],        // Make value from the 12th index
            model: req[13],       // Model value from the 13th index
            regNo: req[14],
            color: req[9]     // Registration Number value from the 14th index
          }));
          //setRequests(formattedRequests);
          //setFilteredRequests(formattedRequests.filter(req => req.status !== 'Delivered'));
          setDeliveredRequests(formattedRequests);
        }
      })
      .catch(error => {
        console.error('Error fetching requests:', error);
      });
    setShowDelivered(!showDelivered);
  };

  function handleDecalDelete(id)
  {
    const url = keys.APIPATH1 + '/deleteDecal.php';
    axios.post(url, { id: id })
      .then(response => {
        alert("Done");
        getRequests(); // Refresh the list after submission
      })
      .catch(error => {
        console.error('Error approving decal:', error);
      });
  }
  const [deliveredSearchQuery, setDeliveredSearchQuery] = useState(""); // New state for search


  const filteredDeliveredRequests = deliveredRequests.filter(request =>
    request.userName.toLowerCase().includes(deliveredSearchQuery.toLowerCase()) ||
    request.vehicleInfo.toLowerCase().includes(deliveredSearchQuery.toLowerCase()) ||
    request.decalNo.toLowerCase().includes(deliveredSearchQuery.toLowerCase()) ||
    request.status.toLowerCase().includes(deliveredSearchQuery.toLowerCase())
  );
  

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>All Requests</Typography>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
          <TextField
            label="Filter by Name"
            variant="outlined"
            size="small"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
          <TextField
            label="Filter by Vehicle Number"
            variant="outlined"
            size="small"
            value={vehicleFilter}
            onChange={(e) => setVehicleFilter(e.target.value)}
          />
          <TextField
            label="Filter by Status"
            variant="outlined"
            size="small"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          />
          <TextField
            label="Filter by Decal No."
            variant="outlined"
            size="small"
            value={decalNoFilter}
            onChange={(e) => setDecalNoFilter(e.target.value)}
          />
        </Box>
        
        <Button
    variant="contained"
    color="primary"
    onClick={handleShowDelivered}
    disabled={isLoadingDelivered}
  >
  {isLoadingDelivered ? 'Loading...' : (showDelivered ? 'Hide Delivered' : 'Show Delivered')}
</Button>

<div style={{marginTop: `20px`}}></div>

{!showDelivered ? (
  <DataGrid
  rows={filteredRequests} // Your filtered requests go here
  columns={columns}       // Your columns definition
  onRowClick={(params) => handleClickOpen(params.id, params.row)} // Trigger modal on row click
  pageSize={10}
  autoHeight
/>

) : ("")}

<div style={{ height: 400, width: '100%', marginTop: '20px' }}>

{showDelivered && (
  <>
    <TextField
      label="Search Delivered"
      variant="outlined"
      size="small"
      value={deliveredSearchQuery}
      onChange={(e) => setDeliveredSearchQuery(e.target.value)}
      fullWidth
      sx={{ mb: 2 }}
    />
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>User Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Vehicle Info</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Status</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Request Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Issued Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Decal No.</th>
          </tr>
        </thead>
        <tbody>
          {filteredDeliveredRequests.map((row) => (
            <tr key={row.uniqId}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.userName}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.vehicleInfo}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.status}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.reqDate}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.issueDate}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{row.decalNo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  </>
)}
</div>


      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "600px" } }}
      >
        <DialogTitle sx={{ backgroundColor: "#f5f5f5" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Request Details
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Vehicle Make:
            </Typography>
            <TextField
              fullWidth
              value={selectedRow?.make}
              onChange={(e) => setSelectedRow({ ...selectedRow, make: e.target.value })}
              variant="outlined"
              sx={{ mt: 1 }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Vehicle Model:
            </Typography>
            <TextField
              fullWidth
              value={selectedRow?.model}
              onChange={(e) => setSelectedRow({ ...selectedRow, model: e.target.value })}
              variant="outlined"
              sx={{ mt: 1 }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Vehicle Color:
            </Typography>
            <TextField
              fullWidth
              value={selectedRow?.color}
              onChange={(e) => setSelectedRow({ ...selectedRow, color: e.target.value })}
              variant="outlined"
              sx={{ mt: 1 }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Registration Number:
            </Typography>
            <TextField
              fullWidth
              value={selectedRow?.regNo}
              onChange={(e) => setSelectedRow({ ...selectedRow, regNo: e.target.value })}
              variant="outlined"
              sx={{ mt: 1 }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Driver Name:
            </Typography>
            <TextField
              fullWidth
              value={selectedRow?.driverName}
              onChange={(e) => setSelectedRow({ ...selectedRow, driverName: e.target.value })}
              variant="outlined"
              sx={{ mt: 1 }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Driver Number:
            </Typography>
            <TextField
              fullWidth
              value={selectedRow?.driverNumber}
              onChange={(e) => setSelectedRow({ ...selectedRow, driverNumber: e.target.value })}
              variant="outlined"
              sx={{ mt: 1 }}
            />
          </Box>

          {/* Radio Button Group for Pickup Method */}
          <Box sx={{ mb: 2 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend" sx={{ fontWeight: "bold" }}>
                Picked Up By:
              </FormLabel>
              <RadioGroup
                row
                value={pickedUpBy} // Use the pickedUpBy state
                onChange={handlePickedUpByChange} // Handle changes
              >
                <FormControlLabel value="Self" control={<Radio />} label="Self" />
                <FormControlLabel value="Driver" control={<Radio />} label="Driver" />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* Displaying the list of students */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              Students:
            </Typography>
            {selectedRow?.students?.length > 0 ? (
              <Box sx={{ pl: 2 }}>
                {selectedRow.students.map((student, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography variant="body1">
                      <strong>Name:</strong> {student.name} | 
                      <strong> Grade:</strong> {student.grade}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No students available.
              </Typography>
            )}
          </Box>

          <Divider sx={{ my: 2 }} />
          <TextField
            fullWidth
            label="Decal No."
            value={decalInput}
            onChange={handleDecalInputChange}
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
  <Button
    onClick={() => {
      const gradeText = selectedRow?.grade.replace('Grade ', '');
      const gradeNumber = parseInt(gradeText, 10);
      let displayText = '';

      if (!isNaN(gradeNumber)) {
        if (gradeNumber <= 5) {
          displayText = 'ES'; // Elementary School
        } else if (gradeNumber <= 8) {
          displayText = 'MS'; // Middle School
        } else {
          displayText = 'HS'; // High School
        }
      }

      handleSubmit({
        id: selectedRow?.id,
        vehicleInfo: selectedRow?.vehicleInfo,
        driverName: selectedRow?.driverName,
        driverNumber: selectedRow?.driverNumber,
        displayText: displayText,
        make: selectedRow?.make,
        model: selectedRow?.model,
        regNo: selectedRow?.regNo,
        pickedUpBy: pickedUpBy, // Include the picked-up-by information
        students: selectedRow?.students,
        delivered: 'no'
      });
    }}
    color="primary"
    variant="contained"
  >
    Submit
  </Button>
  <Button
    onClick={() => {
      const gradeText = selectedRow?.grade.replace('Grade ', '');
      const gradeNumber = parseInt(gradeText, 10);
      let displayText = '';

      if (!isNaN(gradeNumber)) {
        if (gradeNumber <= 5) {
          displayText = 'ES'; // Elementary School
        } else if (gradeNumber <= 8) {
          displayText = 'MS'; // Middle School
        } else {
          displayText = 'HS'; // High School
        }
      }

      handleSubmit({
        id: selectedRow?.id,
        vehicleInfo: selectedRow?.vehicleInfo,
        driverName: selectedRow?.driverName,
        driverNumber: selectedRow?.driverNumber,
        displayText: displayText,
        make: selectedRow?.make,
        model: selectedRow?.model,
        regNo: selectedRow?.regNo,
        pickedUpBy: pickedUpBy, // Include the picked-up-by information
        students: selectedRow?.students,
        delivered: 'yes'
      });
    }}
    color="primary"
    variant="contained"
  >
    Submit & Deliver
  </Button>
  {selectedRow?.status === "Alloted" && (
    <Button onClick={handleDeliveredClick} color="secondary" variant="contained">
      Mark as Delivered
    </Button>
  )}
  <Button
    onClick={() => {
      handleDecalDelete(selectedRow?.id);
      handleClose();
    }}
    color="error"
    variant="contained"
  >
    Delete
  </Button>
  <Button onClick={handleClose} color="primary">
    Close
  </Button>
</DialogActions>

      </Dialog>
    </Layout>
  );
};

export default DecalRequests;
