import React, { useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, TextField, Typography, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ReportsHS = () => {
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [reportLogs, setReportLogs] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [showPlainTable, setShowPlainTable] = useState(false);

  const fetchData = async () => {
    try {
      const logsSnapshot = await getDocs(collection(db, 'logs'));
      const usersSnapshot = await getDocs(collection(db, 'users'));

      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const logsData = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setUsers(usersData);
      setLogs(logsData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
  
    if (logs.length === 0 || users.length === 0) {
      await fetchData();
    }
  
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
  
    const groupedLogs = new Map();
  
    // Find students within grade levels 9 to 12
    const filteredStudents = users.filter(user => 
      user.role === 'student' && 
      user.grade_level >= -2 && 
      user.grade_level <= 5
    );
  
    // Create a set to track students who have a log entry (Present)
    const presentStudentKeys = new Set();
  
    logs.forEach(log => {
      const logDate = log.datetime && typeof log.datetime === 'string' ? log.datetime.split(' ')[0] : null;
  
      if (logDate && logDate >= formattedFromDate && logDate <= formattedToDate) {
        const user = users.find(user => String(user.userId) === String(log.id));
  
        if (user && user.role === 'student' && user.grade_level >= -2 && user.grade_level <= 5) {
          const key = `${user.userId}-${logDate}`;
          presentStudentKeys.add(key);
  
          if (!groupedLogs.has(key)) {
            groupedLogs.set(key, {
              id: log.id,
              userId: user.userId,
              name: user.name,
              grade: user.grade_level,
              date: logDate,
              logTime: log.datetime ? log.datetime.split(' ')[1] : '', 
              mode: log.mode,
              present: true,
            });
          }
        }
      }
    });
  
    // Add students who are absent (i.e., no logs)
    filteredStudents.forEach(student => {
      const dateRange = [formattedFromDate, formattedToDate];
      dateRange.forEach(date => {
        const key = `${student.userId}-${date}`;
        if (!presentStudentKeys.has(key)) {
          groupedLogs.set(key, {
            id: student.userId,
            userId: student.userId,
            name: student.name,
            grade: student.grade_level,
            date: date,
            logTime: '', // No log time
            mode: '', // No mode
            present: false,
          });
        }
      });
    });
  
    setReportLogs([...groupedLogs.values()]);
    setIsLoading(false);
  };  

  const handlePlainTableToggle = () => {
    setShowPlainTable(!showPlainTable);
  };

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>ES Reports</Typography>
        
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            dateFormat="yyyy-MM-dd"
            customInput={<TextField variant="outlined" label="From Date" />}
          />
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            dateFormat="yyyy-MM-dd"
            customInput={<TextField variant="outlined" label="To Date" />}
          />
          <Button variant="contained" color="secondary" onClick={handleSearch} disabled={isLoading}>
            {isLoading ? 'Loading...' : "Search"}
          </Button>
          <Button variant="outlined" color="primary" onClick={handlePlainTableToggle}>
            {showPlainTable ? "Hide Plain Table" : "Show Plain Table to Copy"}
          </Button>
        </Box>

        {showPlainTable ? (
          <Box sx={{ mt: 3 }}>
            <table>
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Name</th>
                  <th>Grade</th>
                  <th>Mode</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Log Time</th>
                </tr>
              </thead>
              <tbody>
                {reportLogs.map((log, index) => (
                  <tr key={index}>
                    <td>{log.userId}</td>
                    <td>{log.name}</td>
                    <td>{log.grade}</td>
                    <td>{log.mode}</td>
                    <td style={{ color: log.present ? 'green' : 'red' }}>
                      {log.present ? 'Present' : 'Absent'}
                    </td>
                    <td>{log.date}</td>
                    <td>{log.logTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        ) : (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Toggle to Plain Table to view data.</Typography>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default ReportsHS;
