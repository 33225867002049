const keys = {
    APIPATH : 'https://raptornet.aischennai.org/screening-manager/new-screening-api',
    APIPATH1 : 'https://raptornet.aischennai.org/screening-manager/leave-application',
    //APIPATH : 'http://localhost/screening-new-final',
    BASEPATH : 'http://localhost:3000',
    APIKEY : '',
    IMGPATH: 'https://raptornet.aischennai.org/screening-manager/api/images',
    UPLOADPATH: 'https://raptornet.aischennai.org/screening-manager/new-screening-api/uploads'
    //IMGPATH: 'https://localhost/screening-new-final/uploads',
    //UPLOADPATH: 'http://localhost/screening-new-final/uploads'
};

export default keys;
