import React, { useEffect, useState } from "react";
import { Layout } from "../../components/dashboard/layout";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { styled } from "@mui/material/styles";
import axios from "axios";
import keys from '../../constants.js';
import {
  Card,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  Typography,
  CardContent,
  Paper,
  Alert,
  Grid,
  Stack,
  Button,
  Box,
  Autocomplete,
  TextField,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { StackLayout } from "src/components/dashboard/stackLayout";
import { QrReader } from "react-qr-reader";
import "./rfidManagement.scss";
import useScanDetection from 'use-scan-detection';

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const RfidManagement = ({ qrCheckin, ischeckout }) => {
  const [searchResult, setSearchResult] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [isClicked, setIsClicked] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showQrCheckin,setShowQrCheckin] = useState(qrCheckin);

  const [users, setUsers] = React.useState([]);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  function getScanData(proxi)
  {
    let url = '';
    if(ischeckout)
    {
      url = keys.APIPATH+'/checkOut.php';
    }
    else
    {
      url = keys.APIPATH+'/checkIn.php';
    }
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {"id": proxi};

		axios.post(url, reqBody)
			.then(response => {
        setSearchResult(response.data.item);
			}
		);
    getUsers();
  }

  //On Scan
  const [value, setValue] = useState("");

  useScanDetection({
    onComplete: (code) => { 
      getScanData(code);
    },
    onError: () => {
      alert("Please try again");
    },
    minLength: 9
  });

  const navigate = useNavigate();

  function getUsers()
  {
    const url = keys.APIPATH+'/getUsers.php';
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {};

		axios.post(url, reqBody)
			.then(response => {
				setUsers(response.data);
			}
		);
  }

  function handleSearch(item)
  {
    setSearchResult(item);
  }

  function handleCheckIn()
  {
    let url = '';
    if(ischeckout)
    {
      url = keys.APIPATH+'/checkOutManual.php';
    }
    else
    {
      url = keys.APIPATH+'/checkInManual.php';
    }

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {"data": searchResult};

		axios.post(url, reqBody)
			.then(response => {
				setSearchResult(response.data.item);
			}
		);
    setIsClicked(true);
  }

  function handleQrCheckIn(result)
  {
    let url = '';
    if(ischeckout)
    {
      url = keys.APIPATH+'/checkOutQr.php';
    }
    else
    {
      url = keys.APIPATH+'/checkInQr.php';
    }

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {"data": result};

		axios.post(url, reqBody)
			.then(response => {
				setSearchResult(response.data.item);
			}
		);

    setShowQrCheckin(false);
  }

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...users]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  return (
    <StackLayout>
      <Box sx={{ p: 2 }}>
        <Autocomplete
          clearOnEscape
          onChange={(e, item) => {
            handleSearch(item)
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => `${option.name} - ${option.aisId}`}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <MagnifyingGlassIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {/* {params.InputProps.endAdornment} */}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Box>

      <br />

      {searchResult === null && showQrCheckin && (
        <div style={lgUp ? {width:"400px", margin:"0 auto"} :{}}>
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
             handleQrCheckIn(result.text);
             return false;
            }

            if (!!error) {
              
            }
          }}
       
        />
        </div>
      )}

      {searchResult !== null && (
        <div className="people-card">
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
              width: "70%",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              border: searchResult.isCheckedIn
                ? "12px solid #5DD414"
                : searchResult.isCheckedOut
                ? "12px solid #E37232"
                : "",
              boxShadow: "none",
            }}
            className="people-card-paper"
          >
            <div style={{ margin: "50px 20px" }}>
              <Grid container spacing={6}>
                <Grid item>
                  <div style={{ width: 128, height: 128 }}>
                    <Img alt="complex" src={keys.IMGPATH+"/"+searchResult.profileImg} />
                  </div>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs style={{ textAlign: "center" }}>
                      <Typography gutterBottom variant="h4">
                        {searchResult.name}{searchResult.grade ? ' - Grade '+searchResult.grade : ''}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {searchResult.aisId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {searchResult.isCheckedIn ?
                (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography>
                      <Button className="statusBtn checkIn">ON CAMPUS</Button>
                    </Typography>
                  </Grid>
                ) : (
                  searchResult.isCheckedOut ? (
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography>
                        <Button className="statusBtn checkOut">OFF CAMPUS</Button>
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )
                )
                }
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    color={searchResult.isCheckedIn ? "#5DD414" : "#E37232"}
                    variant="h6"
                  >
                    <b>
                    {searchResult.isCheckedIn ?
                      "Last checked in at "+moment(searchResult.lastUpdatedDate).format("LLL")
                     : searchResult.isCheckedOut ? (
                      "Last checked out at "+moment(searchResult.lastUpdatedDate).format("LLL")
                     ) : (
                      ""
                     )}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      )}

      {searchResult !== null &&
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            elevation={10}
            color={isClicked ? "success" : "primary"}
            variant={isClicked ? "contained" :"outlined"}
            onClick={() => {
              handleCheckIn()
            }}
          >
            {ischeckout ? (
              "Check-out"
            ) : ("Check-in")}
            
          </Button>
        </div>
      }
    </StackLayout>
  );
};

export default RfidManagement;
