import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  Stack,
  Alert,
  Paper,
  Typography,
  Chip,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as dayjs from "dayjs";

import { Layout } from "../../components/dashboard/layout";
import { useParams } from "react-router-dom";
import { StackLayout } from "src/components/dashboard/stackLayout";
import moment from "moment";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";

import Webcam from "react-webcam";
import { CheckCircleOutline } from "@mui/icons-material";
import "./visitorEdit.scss";

import axios from "axios";
import keys from '../../constants.js';
import loaderIcon from '../../asserts/loader-ajax.gif';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const filter = createFilterOptions();

const visitorTypes = [
  {
    value: "vendor",
    label: "Vendor",
  },
  {
    value: "visitor",
    label: "Visitor",
  },
  {
    value: "contractor",
    label: "Contractor",
  },
];

const visitingHostList = [
  {
    value: "Angel Host",
    label: "Angel Host",
  },
  {
    value: "Krish",
    label: "Krish",
  }
];


const Wrapper = ({ children, preRegister }) => {
  if (preRegister) {
    return (
      <Layout subText="Pre Register">
        <div style={{ padding: "20px" }}>{children}</div>
      </Layout>
    );
  } else {
    return <StackLayout>{children}</StackLayout>;
  }
};

const VisitorEdit = ({ preRegister = false }) => {

  const [visitingHostList, setVisitingHostList] = useState([]);
  const [values, setValues] = useState({
    name: "",
    id: "",
    idNo:null,
    visitorType: "",
    company: "",
    phone: "",
    hostDepartment: "",
    visitingHost: "",
    purposeOfVisit: "",
    location: "Chennai",
    vehicleNo: "",
    picture: "",
    createdTime: dayjs(),
    visitingDate: dayjs(),
    visitorRegistrationType: preRegister ? "preRegister" : "new",
    filepath: "",
    fileUpload: [],
    fileUpload1: [],
    photo:"",
    photo1:"",
    photoPath:"",
    photoPath1:"",
    tempid: ""
  });

  const [file1, setFile1] = useState(false);
  const [file2, setFile2] = useState(false);
  const [photo, setPhoto] = useState(false);
  const [photo1, setPhoto1] = useState(false);
  const [photoPath, setPhotoPath] = useState(false);
  const [photoPath1, setPhotoPath1] = useState(false);
  const [checkedOut, setCheckedOut] = useState(false);

  const [fileCount1, setFileCount1] = useState("");
  const [fileCount2, setFileCount2] = useState("");

  const [companies, setCompanies] = useState([{ title: "Company Name" }]);

  function getCompanies() {
    const url = keys.APIPATH + '/listCompanies.php';
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const reqBody = {};

    axios.post(url, reqBody)
      .then(response => {
        setCompanies(response.data);
      }
      );
  }

  const [states, setStates] = useState([]);

  function getDepartments()
  {
    const url = keys.APIPATH+'/getAllDepartments.php';
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {};

		axios.post(url, reqBody)
			.then(response => {
				setStates(response.data);
			}
		);
  }

  const [success, setSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [picture, setPicture] = useState("");
  let [visitorVal, setVisitorVal] = useState("");
  const open = Boolean(anchorEl);
  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  const params = useParams();
  const handleChange = useCallback((event) => {
    //console.log(event.target.name);
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  const [loader, setLoader] = useState("hide");

  const isNewRegisterVisitor = values.visitorRegistrationType === "new";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [tempHost, setTempHost] = useState("");

  const [base64IMG, setBase64IMG] = useState("");

  function getDeptTemp(dept)
  {
    const url = keys.APIPATH + '/getDepartmentUsers.php';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqBody = { department: dept };

      axios.post(url, reqBody)
        .then(response => {
          setVisitingHostList(response.data);
        }
        );
  }

  function handleSelectChange(event, value) {
    if (event == 'hostDepartment') {
      const url = keys.APIPATH + '/getDepartmentUsers.php';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqBody = { department: value.value ? value.value : value };

      axios.post(url, reqBody)
        .then(response => {
          setVisitingHostList(response.data);
        }
        );
    }
    setValues((prevState) => ({
      ...prevState,
      [event]: value.value ? value.value : value,
    }));
  }

  function handleVisitorSubmit() {
    //console.log(values);
    let title = values.company ? values.company.title ? values.company.title : values.company : '' 
    if (values.name && values.idNo && values.phone && values.visitorType && values.purposeOfVisit != '') {
      setLoader("show");
      const url = keys.APIPATH + '/checkInVisitor.php';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      // const reqBody = { data: values };
      const reqBody = { data: values };
      axios.post(url, reqBody)
        .then(response => {
          if (response.data.status) {
            setLoader("hide");
            setSuccess(true);
            setTempHost(response.data.host);
            setPhoto(response.data.photo);
            setPhoto1(response.data.photo1);
          }
          else {
            alert("Please try again");
          }
        }
        );
    }
    else {
      alert("Please fill-in the required fields");
    }


  }

  function handleFileUpload(event, name) {
    let files = event.target.files;
    var tmp = [];
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = (e) => {
        tmp.push(e.target.result);
      }
    }
    setValues((prevState) => ({
      ...prevState,
      [name]: tmp
    }));

    //setBase64IMG(tmp);

    if (name == 'fileUpload') {
      setBase64IMG(tmp);
      setFile1(true);
      setFileCount1(files.length);
    }

    if (name == 'fileUpload1') {
      setFile2(true);
      setFileCount2(files.length);
    }
  }

  const handleTempID = useCallback((event) => {
  {
      setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
  }});

  const handlePhoneChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));

    if (event.target.value.length > 9) {
      const url = keys.APIPATH + '/getExistingCustomer.php';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };

      const reqBody = { phone: event.target.value };

      axios.post(url, reqBody)
        .then(response => {
          getDeptTemp(response.data.host_department);
          setValues((prevState) => ({
            ...prevState,
            name: response.data.name,
            visitingHost: response.data.visiting_host,
            hostDepartment: response.data.host_department,
            purposeOfVisit: response.data.purpose_of_visit,
            location: response.data.location ? response.data.location : "Chennai",
            vehicleNo: response.data.vehicle_no,
            company:response.data.company ? {title: response.data.company}:'',
            visitorType: response.data.visitor_type,
            photo: response.data.photo?response.data.photo :'',
            photo1: response.data.documents?response.data.documents:'',
            photoPath: response.data.photo ? processAnchor(response.data.photo) : '',
            photoPath1: response.data.documents ? processAnchor(response.data.documents) : '',
          }));

          const reader = new FileReader();

          reader.readAsDataURL(keys.APIPATH+'/'+response.data.photo);

          reader.onload = () => {
            //console.log('called: ', reader);
            setBase64IMG(reader.result);
          }
        }
        );
    }
  });

  function handleCheckOut(id)
  {
    const url = keys.APIPATH+'/checkOutVisitor.php';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {id: id};

		axios.post(url, reqBody)
			.then(response => {
				getPreRegister(id);
			}
		);
  }
  function processAnchor(info)
  {
    var dt = info.split(',');
    var results = [];
    dt.forEach((docu, index) => {
      results.push(
        <><a target="new" key={index} href={keys.APIPATH+'/'+docu} download>{docu.replace('uploads/','')}</a><br/></>
      );
    });
    return results;
  }

  const divRef = useRef();

  const handleDownloadPdf = () => {
    const div = divRef.current;

    html2canvas(div, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(moment(values.visitingDate).format("MMM DD, YYYY")+' - '+values.idNo+'.pdf');
    });
  };

  function getPreRegister(id){
    const url = keys.APIPATH + '/getVisitorDetails.php';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    const reqBody = { id: params.id };

    axios.post(url, reqBody)
      .then(response => {
        if(response.data[0].check_out_time != '')
        {
          setCheckedOut(true);
        }
        else
        {
          setCheckedOut(false);
        }
        
        handleSelectChange("hostDepartment", response.data[0].host_department);
        setValues(
          {
            name: response.data[0].name,
            phone: response.data[0].phone,
            createdDate: response.data[0].dateofVisit,
            createdBy: "--",
            location:response.data[0].location,
            visitorType:response.data[0].visitor_type,
            hostDepartment:response.data[0].host_department,
            visitingHost:response.data[0].visiting_host_id,
            purposeOfVisit: response.data[0].purpose_of_visit,
            vehicleNo:response.data[0].vehicle_no,
            visitorRegistrationType: response.data[0].type,
            company:{title: response.data[0].company_name},
            id: response.data[0].id,
            visitingDate: dayjs(),
            photo: response.data[0].photo,
            photo1: response.data[0].documents,
            photoPath: response.data[0].photo ? processAnchor(response.data[0].photo):'',
            photoPath1: response.data[0].documents ? processAnchor(response.data[0].documents):'',
            createdTime:dayjs(response.data[0].dateofVisit)
          },
        );

        const reader = new FileReader();

        reader.readAsDataURL(keys.APIPATH+'/'+response.data[0].photo);

        reader.onload = () => {
          //console.log('called: ', reader);
          setBase64IMG(reader.result);
        }

      }
      );
  }

  function handleDateChange(newValue)
  {
      setValues((prevState) => ({
        ...prevState,
        visitingDate: newValue,
      }));
      
      const url = keys.APIPATH + '/getVisirtorIDDate.php?dt='+newValue;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };

      axios.post(url, { dt: newValue })
        .then(response => {
          setValues((prevState) => ({
            ...prevState,
            idNo: response.data.num,
          }));
      });
  }
  
  function getVisitorID() {
    const url = keys.APIPATH + '/getVisitorID.php';
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    axios.post(url, {})
      .then(response => {
        setValues((prevState) => ({
          ...prevState,
          idNo: response.data.num,
        }));
      });
  }
  
  useEffect(() => {
    if (preRegister) {
      getPreRegister(params.id);
    }
    getDepartments();
    getVisitorID();
    getCompanies();
  }, []);
  if (success) {

    const color = !isNewRegisterVisitor ? "#E37232" : "green";
    //console.log("success",values);
    return (
      <Wrapper preRegister={preRegister}>
        <div style={{}} className="visitor card-container">
          <Stack
            sx={{ width: "100%", pb: "20px" }}
            spacing={2}
            justifyContent={"center"}
            alignItems="center"
            display={"flex"}
          >
            <Typography variant="h5">
              {isNewRegisterVisitor
                ? `Visitor checked in at ${values.visitingDate.format("LLL")}`
                : `Visitor has been registered successfully`}
            </Typography>
            <CheckCircleOutline
              style={{ color: color, width: "100px", height: "100px" }}
            />
          </Stack>

          <Card
            sx={{
              width: "350px",
              margin: "0 auto",
              borderRadius: "25px",
            }}
            elevation={10}
          >
            <CardContent ref={divRef} sx={{ m: 0, p: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "lightgrey",
                }}
              >
                <Typography variant="h5" sx={{ mt: "10px" }} gutterBottom className="visitorBadge" style={{color:`#fff`}}>
                  AISC <div className="visitorType" style={{display: `contents`,textTransform: `capitalize`}}>{values.visitorType}</div> - {values.idNo}
                </Typography>
              </Box>
              <Grid container xs>
                <Grid xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: "10px",
                    }}
                  >
                    <Avatar
                      alt={values.name}
                      src={/*keys.APIPATH + '/' + photo*/base64IMG}
                      sx={{ width: 56, height: 56 }}
                    ></Avatar>
                  </Box>
                </Grid>

                <Grid xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" component="div">
                      {values.name} {values.lastName}
                    </Typography>
                    <Typography variant="body2" style={{marginTop: `10px`,fontSize: `18px`}}>{values.company.title}</Typography>
                    {/*<Typography variant="body2">
                      <Chip
                        label={
                          isNewRegisterVisitor ? "Checked-in" : "Registered"
                        }
                        color={isNewRegisterVisitor ? "success" : "default"}
                        style={{
                          borderColor: isNewRegisterVisitor ? "inherit" : color,
                          color: isNewRegisterVisitor ? "inherit" : color,
                        }}
                        variant="outlined"
                      />
                      </Typography>*/}
                  </Box>
                </Grid>
                <Grid xs={12} md={12}>
                  <Typography
                    variant="body2"
                    style={{fontWeight: `bold`}}
                    sx={{ m: "10px 20px", textAlign: "center" }}
                  >
                    {values.visitingDate.format("LLL")}
                  </Typography>
                </Grid>
                <br />
                <br />
                <Grid xs={12} md={12}>
                  <Box
                    sx={{
                      border: "1px solid grey",
                      borderRadius: "5px",
                      p: "20px",
                      m: "0px 20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography variant="body2">Visiting Host</Typography>
                      <Typography variant="body2">
                        {tempHost}<br/>{values.hostDepartment}
                      </Typography>
                      {/* <Typography variant="body2">{values.phone}</Typography> */}
                    </div>
                    <div>
                      <p style={{marginTop: `-25px`,fontSize: `15px`}}>Signature</p>
                      
                    </div> 
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <center><button style={{background: `#de4a2b`,color: `#fff`,border: `0px`,padding: `4px 10px`,marginTop: `10px`,marginBottom: `10px`,borderRadius: `4px`}} onClick={handleDownloadPdf}>Download</button></center>
          </Card>

          <div>
            <Grid xs={12} md={12}>
              <Box
                sx={{
                  pt: "30px",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    backgroundColor: "green",
                    color: "white", //"rgb(237, 247, 237)",
                    opacity: "0.9",
                    padding: "6px 16px",
                  }}
                >
                  {" "}
                  Notification has been sent to host
                </Typography>
              </Box>
            </Grid>
          </div>
        </div>
      </Wrapper>
    );
  }
  //console.log(values);
  return (
    <Wrapper preRegister={preRegister}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card elevation={0}>
          {/* <CardHeader
            subheader="The information can be edited"
            title="Visitor Details"
          /> */}
          <Grid container spacing={3}>
            <Grid xs={10} md={10}>
              {!preRegister && (
                <RadioGroup
                  defaultValue="new"
                  value={values.visitorRegistrationType}
                  onChange={handleChange}
                  row
                  name="visitorRegistrationType"
                >
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label="New Visitor"
                  />
                  <FormControlLabel
                    value="preRegister"
                    control={<Radio />}
                    label="Pre-Register Visitor"
                  />
                </RadioGroup>
              )}
            </Grid>
            {/* <Grid xs={2} md={2} style={{ textAlign: "end" }}>
            <div style={{ marginBottom: "0px" }}>
                {preRegister && !checkedOut ? (
                  <Button onClick={() => handleCheckOut(params.id)} style={{float: `right`,top: `0px`,right: `0px`}} color="primary" variant="contained">Check Out</Button>
                ) : (
                  ""
                )}
              </div>
            </Grid> */}
            <Grid xs={2} md={2} style={{ textAlign: "end" }}>
              
              {!isNewRegisterVisitor ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    name="visitingDate"
                    format="MMM DD, YYYY"
                    value={values.visitingDate}
                    onChange={(newValue) => handleDateChange(newValue)}
                    disabled={isNewRegisterVisitor}
                  />
                </LocalizationProvider>
              ) : (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div style={{ width: "18px", height: "18px" }}>
                    <CalendarIcon />{" "}
                  </div>
                  {moment(values.visitingDate).format("MMM DD, YYYY")}
                </div>
              )}
            </Grid>
          </Grid>
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                {(
                  <Grid xs={2} md={2}>
                    <TextField
                      fullWidth
                      label=""
                      name="idNo"
                      onChange={handleChange}
                      required
                      value={values.idNo}
                      InputProps={{
                        readOnly: true,
                        clicked: true
                      }}
                    />
                  </Grid>
                )}
                <Grid xs={2}>
                  <TextField
                    fullWidth
                    label="Temp ID Number"
                    name="tempid"
                    onChange={handleTempID}
                    type="text"
                    value={values.tempid}
                  />
                </Grid>
                <Grid xs={4}>
                  <TextField
                    fullWidth
                    label="Phone Number *"
                    name="phone"
                    onChange={handlePhoneChange}
                    type="number"
                    value={values.phone}
                  />
                </Grid>
                <Grid xs={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name}
                  />
                </Grid>
                
                <Grid xs={6} md={6}>
                <Autocomplete
                options={visitorTypes}
                fullWidth
                label="Visitor Type"
                name="visitorType"
                onChange={(event, value) => value != null ? handleSelectChange("visitorType", value) : ''}
                required
                select
                value={visitorTypes.find((type) => type.value === values.visitorType) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Visitor Type"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  ></TextField>
                )}
              ></Autocomplete>
                </Grid>
                <Grid xs={6} md={6}>
                  {/* <TextField
                    fullWidth
                    label="Company"
                    name="company"
                    onChange={handleChange}
                    required
                    value={values.company}
                  /> */}
                  <Autocomplete
                    value={values.company}
                    name="company"
                    fullWidth
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        // console.log("test here1", newValue);
                        setValues((prevState) => ({
                          ...prevState,
                          company: {
                            title: newValue,
                          },
                        }));
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        // console.log("test here2", newValue);
                        setValues((prevState) => ({
                          ...prevState,
                          company: {
                            title: newValue.inputValue,
                          },
                        }));
                      } else {
                        // console.log("test here3", newValue);
                        //setValues(newValue);
                        setValues((prevState) => ({
                          ...prevState,
                          company: newValue,
                        }));
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={companies}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.title}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Visitor Company"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} md={6}>
                  <Autocomplete
                    options={states}
                    fullWidth
                    label="Host Department"
                    name="hostDepartment"
                    onChange={(event, value) => value!=null?handleSelectChange("hostDepartment", value):''}
                    required
                    select
                    value={states.find((type) => type.value === values.hostDepartment) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Host Department"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      ></TextField>
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid xs={6} md={6}>
                  <Autocomplete
                    options={visitingHostList}
                    fullWidth
                    label="Visiting Host"
                    name="visitingHost"
                    onChange={(event, value) => value!=null?handleSelectChange("visitingHost", value):''}
                    required
                    select
                    value={visitingHostList.find((type) => type.value === values.visitingHost) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Visitor Host"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      ></TextField>
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid xs={preRegister ? 9: 12} md={preRegister ? 9 : 12}>
                  <TextField
                    fullWidth
                    label="Purpost Of Visit"
                    name="purposeOfVisit"
                    onChange={handleChange}
                    required
                    value={values.purposeOfVisit}
                  />
                </Grid>
                {(preRegister) && (
                <Grid xs={preRegister ? 3 : 12} md={preRegister ? 3 : 12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Time"
                    name="createdTime"
                    format="MMM DD,YYYY hh:mm:ss"
                    value={values.createdTime}
                    onChange={(newValue) => {
                      setValues((prevState) => ({
                        ...prevState,
                        createdTime: newValue,
                      }));
                    }}
                    disabled={isNewRegisterVisitor}
                  />
                </LocalizationProvider>
                </Grid>
                )}
                <Grid xs={6} md={6}>
                  <TextField
                    fullWidth
                    label="Location"
                    name="location"
                    onChange={handleChange}
                    required
                    value={values.location}
                  />
                </Grid>
                <Grid xs={6} md={6}>
                  <TextField
                    fullWidth
                    label="Vehicle No"
                    name="vehicleNo"
                    onChange={handleChange}
                    value={values.vehicleNo}
                  />
                </Grid>
                {/*!isNewRegisterVisitor && (
                  <>
                    <Grid xs={6} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="checkinDate"
                          label="Checkin Date"
                          slotProps={{ textField: { fullWidth: true } }}
                          format="MMM DD,YYYY"
                          value={values.checkinDate}
                          onChange={(value) => {
                            setValues((prevState) => ({
                              ...prevState,
                              checkinDate: value,
                            }));
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={6} md={6} className="time-picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="Checkin Time"
                            onChange={(value) => {
                              setValues((prevState) => ({
                                ...prevState,
                                checkinTime: value,
                              }));
                            }}
                            value={values.checkinTime}
                            slotProps={{ textField: { fullWidth: true } }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </>
                  )*/}

                {(preRegister || isNewRegisterVisitor) && (
                  <>
                    <Grid xs={6} md={6}>
                      <label>Photo of the Visitor</label><br /><br />
                      <input
                        type="file"
                        id="upload-button"
                        fullWidth
                        className="upload-button"
                        variant="text"
                        name="fileUpload[]"
                        // accept="image/png"
                        onChange={e => handleFileUpload(e, "fileUpload")}
                        value={values.filepath}
                        multiple
                      /><br />
                      <i style={{ fontStyle: `normal`, paddingTop: `10px`, display: `block` }}>{file1 ? fileCount1 + " photo(s) attached" : ""}</i>
                      <br/>
                      {(preRegister || isNewRegisterVisitor && values.photo) && (
                        values.photoPath
                      )}
                    </Grid>

                    <Grid xs={6} md={6}>
                      <label>Other Attachments</label><br /><br />
                      <input
                        type="file"
                        id="upload-button1"
                        fullWidth
                        className="upload-button"
                        variant="text"
                        name="fileUpload1[]"
                        // accept="image/png"
                        onChange={e => handleFileUpload(e, "fileUpload1")}
                        value={values.filepath}
                        multiple
                      />
                      <br />
                      <i style={{ fontStyle: `normal`, paddingTop: `10px`, display: `block` }}>{file2 ? fileCount2 + " document(s) attached" : ""}</i>
                      <br/>
                      {(preRegister || isNewRegisterVisitor && values.photo1) && (
                        values.photoPath1
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <img className={"loaderIcon " + loader + "_loader"} src={loaderIcon} />
          <CardActions sx={{ justifyContent: "center" }}>
            {!checkedOut? (
            <Button
              onClick={() => {
                handleVisitorSubmit()
              }}
              variant="contained"
              color={"success"}
            >
              {preRegister ? 'Check-In' : 'Submit'}
            </Button>
            ): (
              ""
            )}
          </CardActions>
        </Card>
      </form>
    </Wrapper>
  );
};

export default VisitorEdit;
