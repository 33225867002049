import styled from "@emotion/styled"



export const  LoginContainer =  styled.div`
background-color: #fff;
 height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content:center;
  align-items:center;
  `