import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, TextField, Typography, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Reports = () => {
  const [logs, setLogs] = useState([]);
  const [reportLogs, setReportLogs] = useState([]);
  const [searchDate, setSearchDate] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Function to format dates as YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchData = async (searchDate) => {
    try {
      const formattedSearchDate = formatDate(searchDate);
  
      console.log(`Fetching data for the date: ${formattedSearchDate}`);
  
      // Create a Firestore query to filter logs based on the datetime range for the given date
      const logsQuery = query(
        collection(db, 'logs'),
        where('datetime', '>=', formattedSearchDate),
        where('datetime', '<', `${formattedSearchDate}T23:59:59`) // Ensuring that it is within the same date
        // // Limit to 100 entries by default limit(100
      );
  
      const logsSnapshot = await getDocs(logsQuery);
      const logsData = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      console.log('Fetched logs:', logsData);
  
      setLogs(logsData);  // Store logs for the given date
      setReportLogs(logsData);  // Display the logs in the table
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  
  // Search handler when clicking search
  const handleSearch = async () => {
    setIsLoading(true);
  
    const formattedSearchDate = formatDate(searchDate);
  
    console.log('Searching logs for:', formattedSearchDate, searchQuery);
  
    // If ID is provided, filter by both ID and date
    const filteredLogs = logs.filter(log =>
      (log.id && log.id.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (log.datetime && log.datetime.includes(formattedSearchDate))
    );
  
    console.log('Filtered logs:', filteredLogs);
  
    setReportLogs(filteredLogs);
    setIsLoading(false);
  };

  // Fetch logs for today's date when the component is mounted
  useEffect(() => {
    fetchData(searchDate);
  }, []); // Empty dependency array means this effect runs only once on component mount

  // Search query handler
  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>All Reports</Typography>

        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <DatePicker
            selected={searchDate}
            onChange={(date) => setSearchDate(date)}
            dateFormat="yyyy-MM-dd"
            customInput={<TextField variant="outlined" label="Select Date" />}
          />
          <TextField
            variant="outlined"
            label="Search by ID"
            value={searchQuery}
            onChange={handleSearchQuery}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="secondary" onClick={handleSearch} disabled={isLoading}>
            {isLoading ? 'Loading...' : "Search"}
          </Button>
        </Box>

        {/* Table to display log data */}
        <Box sx={{ mt: 3 }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Date/Time</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>ID</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Mode</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Type</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Image</th>
              </tr>
            </thead>
            <tbody>
              {reportLogs.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>No logs found for the selected date.</td>
                </tr>
              ) : (
                reportLogs.map((log, index) => (
                  <tr key={index}>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.datetime}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.id}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.mode}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{log.type}</td>
                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                      <img src={`https://raptornet.aischennai.org/screening-manager/api/images/${log.id}.jpg`} alt={log.id} width="50" height="50" />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Layout>
  );
};

export default Reports;