import React, { useEffect, useState } from 'react';
import { collection, getDoc, doc, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, Typography, Avatar, Alert, Skeleton } from '@mui/material';
import useScanDetection from 'use-scan-detection';

const CheckOut = () => {
  const [inputData, setInputData] = useState(null);
  const [matchedData, setMatchedData] = useState(null);
  const [alert, setAlert] = useState(null);
  const [indianTime, setIndianTime] = useState(null);
  const [loading, setLoading] = useState("");

  useScanDetection({
    onComplete: (input) => handleUsbInput(input),
    minLength: 3,
    maxLength: 20,
    averageSpeed: 30,
  });

  const updateTime = () => {
    const now = new Date();
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    
    const formatter = new Intl.DateTimeFormat('en-IN', options);
    const parts = formatter.formatToParts(now);
    
    const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value} ${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;
    console.log(formattedDate);
    setIndianTime(formattedDate);
  };

  const addLogEntry = async (userId) => {
    try {
      const logRef = collection(db, 'logs');
      await addDoc(logRef, {
        datetime: indianTime,
        id: userId,
        type: 'out'
      });
      console.log('Log entry added successfully');
    } catch (error) {
      console.error('Error adding log entry:', error);
    }
  };

  const handleUsbInput = async (input) => {
    setLoading("Loading...");
    setMatchedData(null); // Clear previous data
    var epc = input.replaceAll('Shift', '').toUpperCase();
    setInputData(epc);
    console.log('Normalized EPC:', epc);

    const docRefUpper = doc(db, 'id_data', epc);
    const docRefLower = doc(db, 'id_data', epc.toLowerCase());

    console.log('Querying id_data documents:', docRefUpper.path, 'and', docRefLower.path);

    const [docSnapUpper, docSnapLower] = await Promise.all([
      getDoc(docRefUpper),
      getDoc(docRefLower)
    ]);

    let idData, userId;
    if (docSnapUpper.exists()) {
      idData = docSnapUpper.data();
      userId = idData[epc];
      console.log('Found uppercase id_data document:', idData);
    } else if (docSnapLower.exists()) {
      idData = docSnapLower.data();
      userId = idData[epc.toLowerCase()];
      console.log('Found lowercase id_data document:', idData);
    } else {
      console.log("No matching document found in id_data collection");
      setMatchedData(null);
      setAlert({ severity: 'error', message: 'No matching EPC found in the database.' });
      return;
    }

    if (userId) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setMatchedData({
          name: userData.name,
          userId: userData.userId,
          imageUrl: `https://raptornet.aischennai.org/screening-manager/api/images/${userData.userId}.jpg`
        });
        //console.log('Matched user data:', matchedData);
        setLoading("");
        setAlert(null); // Clear any existing alert

        // Add log entry for successful scan
        await addLogEntry(userData.userId);
      } else {
        //console.log("No matching user found in users collection");
        setLoading("");
        setMatchedData(null);
        setAlert({ severity: 'warning', message: 'EPC found, but no matching user data available.' });
      }
    } else {
      //console.log("No userId found for the given EPC in id_data document");
      setLoading("");
      setMatchedData(null);
      setAlert({ severity: 'error', message: 'EPC found, but no associated user ID.' });
    }
  };

  useEffect(() => {
    updateTime();
  }, []);

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>User Check-Out</Typography>
        {alert && (
          <Alert severity={alert.severity} sx={{ mb: 2 }}>
            {alert.message}
          </Alert>
        )}
        <Box>
          <Typography style={{fontSize:`25px`}}>{loading}</Typography>
        </Box>
        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 3,
            p: 3,
            border: '6px solid #ea7054',
            borderRadius: '10px',
            backgroundColor: '#d25b3d1a',
            mt: 3
          }}>
            <Skeleton variant="circular" width={150} height={150} />
            <Box>
              <Skeleton variant="text" width={200} height={40} />
              <Skeleton variant="text" width={150} height={40} />
            </Box>
          </Box>
        ) : matchedData && (
          <Box className="blinking-border-out" sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 3,
            p: 3,
            border: '6px solid #ea7054',
            borderRadius: '10px',
            backgroundColor: '#d25b3d1a',
            mt: 3
          }}>
            <Avatar 
              src={matchedData.imageUrl} 
              alt={matchedData.name}
              sx={{ width: 150, height: 150 }}
            />
            <Box>
              <Typography variant="h4">{matchedData.name}</Typography>
              <Typography variant="h4">{matchedData.userId}</Typography>
            </Box>
            <Box className="badgeBoxOut">
              <Typography variant="h4">OFF CAMPUS</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default CheckOut;