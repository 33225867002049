// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAfA1D0utCcYdYcrsAcKYZXA0d3s7eU3qw",
    authDomain: "raptornet-gate.firebaseapp.com",
    projectId: "raptornet-gate",
    storageBucket: "raptornet-gate.appspot.com",
    messagingSenderId: "465293996780",
    appId: "1:465293996780:web:23aeb84f3fb873a2deebc9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
