import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  Stack,
  Alert,
  Paper,
  Typography,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as dayjs from "dayjs";

import { Layout } from "../../components/dashboard/layout";
import { useParams, useNavigate } from "react-router-dom";
import { StackLayout } from "src/components/dashboard/stackLayout";
import moment from "moment";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";

import Webcam from "react-webcam";
import { CheckCircleOutline } from "@mui/icons-material";

import axios from "axios";
import keys from '../../constants.js';
import loaderIcon from '../../asserts/loader-ajax.gif';

import { DataGrid } from '@mui/x-data-grid';

import UserCard from './UserCard';

import ReactLogo from "../../asserts/RaptorNet_color.svg";


const AttestationForm = () => {

    const { id } = useParams();

    const [users, setUsers] = useState("");

    const navigate = useNavigate();

    function getDetails() {
        const url = keys.APIPATH + '/getVisitorDetails.php';
        const requestOptions = {
          method: 'GET',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json' },
        };
    
        axios.post(url, {id: id})
          .then(response => {
            setUsers(response.data[0]);
        });
    }

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <Layout>
            <Box sx={{ p: 4 }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <div id="printOnly"><img src={ReactLogo}/></div>
                    <Typography variant="h5" gutterBottom style={{color: `#a82e2e`,fontWeight: `bold`}}>Visitor Self-Attestation - {users.name}</Typography>
                    <div className="card">
                        <div className="card-body">
                            <p style={{borderBottom: `2px solid #d1eaff`,paddingBottom: `10px`}}><b>User {users.name} has read the following carefully and signed the document digitally.</b></p>
                            <p>By entering the American International School Chennai (AISC), I am agreeing to the following:</p>
                            <ul className="instructions">
                            <li>I am entering the campus on essential business and will stay only as long as necessary.</li>
                            <li>
                                I will not engage with students in a capacity outside of my professional role. Should you have any queries about your interaction with students, please speak with the Main Receptionist.
                            </li>
                            <li>I will remain in the designated spaces allotted for my event/purpose on campus and will not explore.</li>
                            <li>
                                I will not take pictures/videos of students who are not my own. To honor the privacy rights of students, we insist that no photos/videos of AISC students are shared in news media, social media or outside groups without written permission.
                            </li>
                            </ul>
                            <p>Additionally:</p>
                            <ul className="instructions">
                            <li>Adult visitors must only use the bathrooms that are labeled for adults.</li>
                            <li>Tobacco, vaping, and alcohol use is strictly prohibited on campus and within a 100-yard radius of AISC’s main gate.</li>
                            <li>Visitor cooperation is critical during any emergency situation. Visitors must follow all directives given by AISC staff and the security team.</li>
                            <li>All visitors must wear their appropriate ID badges while on campus.</li>
                            <li>The AISC Campus is under CCTV surveillance.</li>
                            </ul>
                            <form className="attForm">
                                <div class="form-group">
                                    <label for="visitorName">Visitor Name: </label>
                                    {users.name}
                                </div>
                                <div class="form-group">
                                    <label for="date">DateTime: </label>
                                    {users.check_in_time}
                                </div>
                                <div class="form-group">
                                    <label for="contact">Contact #: </label>
                                    {users.phone}
                                </div>
                                <div class="form-group">
                                    <label for="visitorSignature">Visitor Signature: </label>
                                    <p>User accepted for the above terms and digitally signed the document himself</p>
                                </div>
                                <div class="form-group">
                                    <label for="securitySignature">Security Signature: </label>
                                    <p>System Generated</p>
                                </div>
                            </form>
                            <Button id="hideBtn" variant="contained" color="primary" onClick={() => window.print()}>Print</Button>
                        </div>
                    </div>
                </div>
            </Box>
        </Layout>
    );
};

export default AttestationForm;
