import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import keys from '../../constants.js';

const UserCard = ({ user, onClick }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ maxWidth: 345 }} style={{marginRight: `10px`,marginTop:`15px`}} onClick={() => onClick(user)}>
        <CardMedia
          component="img"
          height="150"
          image={keys.APIPATH+'/'+user.photo}
          alt={user.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {user.name}
          </Typography>
          <Typography gutterBottom variant="p" component="div">
          <div style={{fontSize: `12px`}}>{user.description}</div><div style={{fontSize: `10px`}}>{user.checkedInTime}</div>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserCard;
