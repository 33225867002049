import React, { useEffect, useState } from "react";
import { Layout } from "../../components/dashboard/layout";
import { DataGrid } from "@mui/x-data-grid";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Ensure this imports the initialized Firestore instance
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Datagrid = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map((doc, index) => ({ 
          uniqueId: index + 1, // Add a unique incremented id
          id: doc.id, // Firestore document ID
          ...doc.data() 
        }));
        setItems(usersData);
        setFilteredItems(usersData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = items.filter(item => 
      (typeof item.name === 'string' && item.name.toLowerCase().includes(query)) ||
      (typeof item.userId === 'string' && item.userId.toLowerCase().includes(query)) ||
      (typeof item.role === 'string' && item.role.toLowerCase().includes(query)) ||
      (typeof item.rfid === 'string' && item.rfid.toLowerCase().includes(query))
    );
    setFilteredItems(filtered);
  };

  const handleRowClick = (params) => {
    navigate(`/edit-user/${params.row.userId}`);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'role', headerName: 'Type', width: 250 },
    { field: 'rfid', headerName: 'Biometric ID', width: 250 },
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img
          src={`https://raptornet.aischennai.org/screening-manager/api/images/${params.row.id}.jpg`}
          alt={params.row.name}
          style={{
            width: 50,
            height: 50,
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
      ),
    }
  ];

  return (
    <Layout>
      <TextField
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearch}
        style={{ marginBottom: '20px', width: '300px' }}
      />
      <div style={{ height: '900px', width: '100%' }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataGrid
            rows={filteredItems}
            columns={columns}
            pageSize={5}
            checkboxSelection
            getRowId={(row) => row.id} // Use uniqueId as the row identifier
            onRowClick={handleRowClick}
          />
        )}
      </div>
    </Layout>
  );
};

export default Datagrid;
