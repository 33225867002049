import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, TextField, Typography, Button } from '@mui/material';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ReportsStaff = () => {
  const [users, setUsers] = useState([]);
  const [logs, setLogs] = useState([]);
  const [reportLogs, setReportLogs] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  // Function to format dates as YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Fetch users for the dropdown
  const fetchUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({
        value: doc.id,
        label: doc.data().name || doc.id, // Use 'name' if available, otherwise fallback to the ID
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Fetch logs based on selected user and date range
  const formatDateForLogs = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const fetchLogs = async () => {
    if (!selectedUser) {
      alert("Please select a user.");
      return;
    }
  
    setIsLoading(true);
    try {
      const formattedStartDate = formatDateForLogs(startDate);
      const formattedEndDate = formatDateForLogs(endDate);
  
      console.log("Fetching logs for user:", selectedUser.value);
      console.log("Date range:", formattedStartDate, "to", formattedEndDate);
  
      const logsQuery = query(
        collection(db, 'logs'),
        where('id', '==', selectedUser.value),
        where('datetime', '>=', formattedStartDate),
        where('datetime', '<=', formattedEndDate)
      );
  
      const logsSnapshot = await getDocs(logsQuery);
  
      if (logsSnapshot.empty) {
        console.log("No logs found for the specified user and date range.");
        setReportLogs([]); // Clear previous results
        return;
      }
  
      const logsData = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Logs fetched:", logsData);
      setReportLogs(logsData);
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      setIsLoading(false);
    }
  };
   
  console.log("Selected User:", selectedUser);


  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>Attendance Reports</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
          {/* Searchable User Dropdown */}
          <Select
  options={users}
  value={selectedUser}
  onChange={(selected) => setSelectedUser(selected)}
  placeholder="Search and select a user"
/>



          {/* From and To Date Filters */}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<TextField variant="outlined" label="From Date" />}
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<TextField variant="outlined" label="To Date" />}
            />
          </Box>

          {/* Search Button */}
          <Button
            variant="contained"
            color="secondary"
            onClick={fetchLogs}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Fetch Report'}
          </Button>
        </Box>

        {/* Logs Table */}
        <Box sx={{ mt: 3 }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Date/Time</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>User ID</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Mode</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Type</th>
                <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Image</th>
              </tr>
            </thead>
            <tbody>
            {reportLogs.map((log, index) => (
  <tr key={index}>
    <td>{log.datetime || "N/A"}</td>
    <td>{log.id || "N/A"}</td>
    <td>{log.mode || "N/A"}</td>
    <td>{log.type || "N/A"}</td>
    <td>
      <img src={`https://raptornet.aischennai.org/screening-manager/api/images/${log.id}.jpg`} alt={log.userId} width="50" height="50" />
    </td>
  </tr>
))}
            </tbody>
          </table>
        </Box>
      </Box>
    </Layout>
  );
};

export default ReportsStaff;
