import React from "react";
import { LoginContainer } from "./login.style";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactLogo from "../../asserts/RaptorNet_color.svg";
import { ReactSVG } from "react-svg";
import { GoogleLogin } from '@react-oauth/google';
import "./login.scss";
import jwt_decode from "jwt-decode";
import axios from "axios";
import keys from '../../constants.js';

const Login = () => {
  const navigate = useNavigate();

  const responseMessage = (response) => {
    let uData = jwt_decode(response.credential);
    localStorage.setItem('userData',JSON.stringify(uData));

    if(uData.email == 'securityscreening@aischennai.org')
    {
      localStorage.setItem('userRole',JSON.stringify('all'));
      localStorage.setItem('userSection',JSON.stringify('all'));
      navigate('/dashboard');
    }
    else
    {
      const url = keys.APIPATH+'/authUser.php';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
		const reqBody = {email: uData.email};
      axios.post(url, reqBody)
        .then(response => {
          if(response.data.status)
          {
            localStorage.setItem('userRole',JSON.stringify(response.data.role));
            localStorage.setItem('userSection',JSON.stringify(response.data.section));
            // localStorage.setItem('userRole','all');
            // localStorage.setItem('userSection','all');
            navigate('/dashboard');
          }
          else
          {
            //
          }
        }
      );
    }    
  };
  const errorMessage = (error) => {
      alert("Please check username/password");
  };

  return (
    <LoginContainer>
      <div className="login-container">
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            width: "300px",
            position: "relative",
            top: "-160px",
          }}
        >
          <ReactSVG src={ReactLogo} className="logo-svg" />
          <div style={{ paddingTop: "22px" }} className="google-login">
            {/* <Button
              onClick={() => {
                navigate("/dashboard");
              }}
              variant="outlined"
            >
              Sign in Google
            </Button> */}
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
          </div>
        </div>
      </div>
    </LoginContainer>
  );
};

export default Login;
