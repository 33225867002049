import React, { useEffect, useState } from 'react';
import { collection, getDocs, setDoc, doc, addDoc, getDoc, query, where, orderBy, limit } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout";
import { Box, Typography, Avatar, Alert, TextField, Autocomplete, Button } from '@mui/material';
import useScanDetection from 'use-scan-detection';

const CheckOut = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [alert, setAlert] = useState(null);
  const [indianTime, setIndianTime] = useState("");
  const [loading, setLoading] = useState("");
  const [actionType, setActionType] = useState(null); // To keep track of CheckIn/CheckOut action
  const [checkInTime, setCheckInTime] = useState(null); // To store the check-in time
  
  const [logDateTime, setLogDateTime] = useState(null); // To store the check-in time

  useScanDetection({
    onComplete: (input) => handleUsbInput(input),
    minLength: 3,
    maxLength: 20,
    averageSpeed: 30,
  });

  const updateTime = () => {
    const now = new Date();
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };

    const formatter = new Intl.DateTimeFormat('en-IN', options);
    const parts = formatter.formatToParts(now);

    const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value} ${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;
    setIndianTime(formattedDate);
  };

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersData = querySnapshot.docs.map(doc => doc.data());
      setUsers(usersData);
      //console.log(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const addLogEntry = async (userId, type, mode) => {
    try {
      const logRef = collection(db, 'logs');
      await addDoc(logRef, {
        datetime: indianTime,
        id: userId,
        type: type,
        mode: mode
      });
      console.log('Log entry added successfully');
      setLogDateTime(indianTime); // Store the datetime of the log entry
    } catch (error) {
      console.error('Error adding log entry:', error);
    }
  };

  const handleUsbInput = async (input) => {
    setLoading("Loading...");
    setAlert(null);
    
    const normalizedInput = input.replaceAll('Shift', '').toUpperCase();
    
    try {
      const docRefUpper = doc(db, 'id_data', normalizedInput);
      const docRefLower = doc(db, 'id_data', normalizedInput.toLowerCase());
    
      const [docSnapUpper, docSnapLower] = await Promise.all([
        getDoc(docRefUpper),
        getDoc(docRefLower)
      ]);
    
      let userId = null;
      if (docSnapUpper.exists()) {
        userId = docSnapUpper.data()[normalizedInput];
      } else if (docSnapLower.exists()) {
        userId = docSnapLower.data()[normalizedInput.toLowerCase()];
      }
    
      if (userId) {
        
        const matchedUser = users.find(user => user.userId == userId);
        if (matchedUser) {
          setSelectedUser(matchedUser);
          setActionType('in'); // Set action type to 'in' for check-in
          setCheckInTime(null); // Reset check-in time before new check-in
          handleCheckIn(matchedUser, 'RFID'); // Automatically check-in the user
        } else {
          setAlert({ severity: 'error', message: 'No matching user found for the given RFID.' });
          logError(normalizedInput, 'No matching user found');
        }
      } else {
        setAlert({ severity: 'error', message: 'No matching RFID found in the database.' });
        logError(normalizedInput, 'No matching RFID found');
      }
    } catch (error) {
      console.error("Error checking RFID:", error);
      setAlert({ severity: 'error', message: 'Error processing RFID input.' });
      logError(normalizedInput, 'Error processing RFID input');
    } finally {
      setLoading("");
    }
  };
  

  const logError = async (epc, message) => {
    try {
      await setDoc(doc(db, 'error_logs', epc), {
        epc: epc,
        message: message,
        timestamp: new Date().toISOString()
      });
      console.log('Error log entry added successfully');
    } catch (error) {
      console.error('Error logging to error_logs:', error);
    }
  };

  const handleCheckIn = async (user, type) => {
    setLoading("Processing Check-In...");
    setAlert(null);
    
    // Update the time right before logging the check-in
    const now = new Date();
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    
    const formatter = new Intl.DateTimeFormat('en-IN', options);
    const parts = formatter.formatToParts(now);
    
    const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value} ${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;
    
    setIndianTime(formattedDate); // Update the time in the state
    
    try {
      // Add log entry for check-in
      await addLogEntry(user.userId, 'in', type);
      setCheckInTime(formattedDate); // Store the current time for display
      setAlert({ severity: 'success', message: 'Check-In successful' });
    } catch (error) {
      setAlert({ severity: 'error', message: 'Error processing Check-In' });
    } finally {
      setLoading("");
    }
  };
  
  const fetchLastCheckInTime = async (userId) => {
    try {
      const logsRef = collection(db, 'logs');
      const q = query(logsRef, where("id", "==", userId), where("type", "==", "in"), orderBy("datetime", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const lastCheckIn = querySnapshot.docs[0].data().datetime;
        setCheckInTime(lastCheckIn); // Store the last check-in time
      } else {
        setCheckInTime(null); // No check-in record found
      }
    } catch (error) {
      console.error('Error fetching last check-in time:', error);
    }
  };

  const handleCheckOut = async () => {
    setLoading("Processing Check-Out...");
    setAlert(null);
    setActionType('out');
    try {
      // Add log entry for check-out
      await addLogEntry(selectedUser.userId, 'out');
      setAlert({ severity: 'success', message: 'Check-Out successful' });
    } catch (error) {
      setAlert({ severity: 'error', message: 'Error processing Check-Out' });
    } finally {
      setLoading("");
    }
  };

  useEffect(() => {
    updateTime();
    fetchUsers();
  }, []);

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>Manual User Check-In</Typography>
        {alert && (
          <Alert severity={alert.severity} sx={{ mb: 2 }}>
            {alert.message}
          </Alert>
        )}
        <Autocomplete
          options={users}
          getOptionLabel={(option) => `${option.userId} - ${option.name}`}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
            setActionType(null); // Reset action type when a new user is selected
            setCheckInTime(null); // Reset check-in time when a new user is selected
            if (newValue) {
              fetchLastCheckInTime(newValue.userId); // Fetch the last check-in time when a user is selected
            }
          }}
          renderOption={(props, option) => (
            <li {...props} data-rfid={option.rfid}>
              {`${option.userId} - ${option.name}`}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search User"
              variant="outlined"
              fullWidth
            />
          )}
        />
        {selectedUser && (
  <Box sx={{ mt: 3 }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        p: 3,
        borderRadius: '10px',
        backgroundColor: actionType === 'in' ? '#d4edda' : actionType === 'out' ? '#f8d7da' : 'transparent',
        mt: 3,
        border: actionType === 'in' ? '2px solid #155724' : actionType === 'out' ? '2px solid #721c24' : 'none',
      }}
    >
      <Avatar 
        src={`https://raptornet.aischennai.org/screening-manager/api/images/${selectedUser.userId}.jpg`} 
        alt={selectedUser.name}
        sx={{ width: 150, height: 150 }}
      />
      <Box>
        <Typography variant="h4">{selectedUser.name}</Typography>
        <Typography variant="h4">{selectedUser.userId}</Typography>
        {selectedUser.role === 'student' && (
          <Typography variant="h6">Grade Level: {selectedUser.grade_level}</Typography>
        )}
        {checkInTime && (
          <Typography variant="h6">Check-In Time: {checkInTime}</Typography>
        )}
      </Box>
    </Box>
    <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
      <Button variant="contained" color="primary" onClick={() => handleCheckIn(selectedUser,'manual')} disabled={loading !== ""}>
        Check-In
      </Button>
      <Button variant="contained" color="secondary" onClick={() => handleCheckOut(selectedUser,'manual')} disabled={loading !== ""}>
        Check-Out
      </Button>
    </Box>
  </Box>
)}



      </Box>
    </Layout>
  );
};

export default CheckOut;
