import React, { useEffect, useState } from "react";
import { Layout } from "../../components/dashboard/layout";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import CalendarIcon from "@heroicons/react/24/solid/CalendarIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import CloseIcon from '@mui/icons-material/Close';

import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  OutlinedInput,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  InputAdornment,
  CardContent,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ArrowDownIcon from "@heroicons/react/24/solid/ArrowDownIcon";
import ArrowUpIcon from "@heroicons/react/24/solid/ArrowUpIcon";
import { Scrollbar } from "src/components/common/scrollbar";
import { useNavigate } from "react-router-dom";
import VisitorInfo from "./visitorInfo";
import dayjs from "dayjs";
import "./visitorHistory.scss";
import { Modal } from "@mui/base";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import keys from '../../constants.js';
import { logDOM } from "@testing-library/react";

const VisitorHistory = (internalItems,selectDate) => {
  const [items, setItems] = useState(internalItems);
  const [itemDate, setItemDate] = useState(selectDate);
  const [filterBy, setFilterBy] = useState("");
  const [showModal, setShowModal] = useState(false);
  
  const count = items.length;

  const rowsPerPage = 10;
  const page = 0;

  const onPageChange = () => { };
  const navigate = useNavigate();

  function getHistory()
  {
    const url = keys.APIPATH+'/visitorHistory.php?type=new&dt=';
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {};

		axios.post(url, reqBody)
			.then(response => {
				setItems(response.data.list);
			}
		);
  }
  const [currVisitor, setCurrVisitor] = useState("");
  function handleChange(val)
  {
    //console.log("value",itemDate);
    const url = keys.APIPATH+'/visitorHistory.php?title='+val+'&dt='+itemDate;
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {};

		axios.post(url, reqBody)
			.then(response => {
        setItems(response.data.list);
			}
		);
  }
  function handleRowClick(event)
  {
    setShowModal(true);
    setCurrVisitor(event.row.id);
    /*const url = keys.APIPATH+'/getVisitorDetails.php';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		};
		const reqBody = {id: event.row.id};

		axios.post(url, reqBody)
			.then(response => {
				//
			}
		);*/
  }

  function handleCloseModal()
  {
    setShowModal(false);
    // setShowModal(false);
    //getHistory();
  }

  const onRowsPerPageChange = () => { };
  useEffect(() => {
    if(internalItems.internalItems==undefined){
      //getHistory();
    } else {
      setItems(internalItems.internalItems);
      setItemDate(internalItems.selectDate);
    }
  }, [internalItems]);

  const visitorTypes = [
    {
      label: "Checked-In",
      value: "checkedIn",
    },
    {
      label: "Checked-Out",
      value: "checkedOut",
    },
  ];


  const columns = [
    {
      field: 'visitorName', headerName: 'Visitor Name', flex: 1, sortable: true, renderCell: (params) => {
        return <Stack alignItems="center" direction="row" spacing={2}>
          <Avatar src={keys.APIPATH+'/'+params.row.photo}></Avatar>
          <span>{params.row.visitorName}</span>
        </Stack>
      }
    },
    {
      field: 'visitorIdNo',
      headerName: 'Visitor Id No',
      sortable: true, flex: 1,

    },
    {
      field: 'tempid',
      headerName: 'Temp Id No',
      sortable: true, flex: 1,

    },
    {
      field: 'company',
      headerName: 'Company',
      sortable: true, flex: 1,

    },
    {
      field: 'checkedInTime',
      headerName: 'Checked-In Time',
      sortable: true, flex: 1,
    },
    {
      field: 'checkedOutTime',
      headerName: 'Checked-Out Time',
      sortable: true, flex: 1,
    },
    {
      field: 'visitingHost',
      headerName: 'Visting Host',
      sortable: true, flex: 1,
    }
  ];

  return (
    <div className="visitor-history">
      <div
        style={{
          display: "flex",
          alignContent: "center",
          paddingBottom: "8px",
        }}
      >
        <OutlinedInput
          defaultValue=""
          fullWidth
          placeholder="Search"
          onChange={(value) => { handleChange(value.target.value);}}
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon color="action" fontSize="small">
                <MagnifyingGlassIcon />
              </SvgIcon>
            </InputAdornment>
          }
          sx={{ maxWidth: 500, mr: 2 }}
        />
        {/* <Autocomplete
          options={visitorTypes}
          sx={{ width: 150, fontSize: "14px" }}
          label="Filter By"
          name="filterBy"
          required
          select
          value={filterBy}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter By"
              InputProps={{
                ...params.InputProps,
              }}
            ></TextField>
          )}
        ></Autocomplete> */}
      </div>
     
      <Card>

        <Box sx={{ minWidth: 800 }}>
          <DataGrid rows={items}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            disableRowSelectionOnClick
            className="historyTable"
            pageSizeOptions={[5,10,25,50,100]}
            onRowClick={(props) => {
              handleRowClick(props)
            }}
          />
        </Box>

      </Card>
      {showModal && (
        <Dialog
          maxWidth="xl"
          open
          onClose={() => {
            handleCloseModal()
          }}
        >
          <DialogTitle style={{ marginLeft: "16px" }}>
            <Typography variant="h5"> Visitor Info</Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                handleCloseModal()
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <VisitorInfo userData={currVisitor}/>
        </Dialog>
      )}
    </div>
  );
};

export default VisitorHistory;
